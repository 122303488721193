import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventsService } from 'src/app/events/events.service';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { OpenEventService } from 'src/app/open-event.service';

@Component({
  selector: 'app-modal-select-community',
  templateUrl: './modal-select-community.component.html',
  styleUrls: ['./modal-select-community.component.css']
})
export class ModalSelectCommunityComponent implements OnInit {
  public myCommunities: SluperEvent[] = [];
  public userProfile: DocumentUserProfile | null = null;

  isCommunityOwner: boolean = false;
  isUserEventInvited: boolean = false;
  showCancelButton: boolean = false;

  selectedCommunityHtml: HTMLElement | null = null;
  selectedCommunity: SluperEvent | null = null;
  /**
   * Variável para diferenciar os cliques em "Criar uma comunidade" ou "Entrar".
   * - false: Clicou em "Criar uma comunidade"
   * - true: Clicou em "Entrar"
   * - null: Nenhuma das opções especiais foi clicada
   */
  showHideCreateCommunity: boolean | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      communities: SluperEvent[],
      isCommunityOwner: boolean,
      showCancelButton?: boolean
    },
    private matDialog: MatDialogRef<ModalSelectCommunityComponent>,
    private openEventService: OpenEventService,
    private eventsService: EventsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isCommunityOwner = this.data.isCommunityOwner;
    this.myCommunities = this.data.communities || [];
    this.showCancelButton = this.data.showCancelButton || false;
    this.isUserEventInvited = !!(this.openEventService.getId() && this.openEventService.getType() === 'COMUNIDADE');
  }

  selectCommunity(event: Event, community?: SluperEvent): void {
    const element = event.target as HTMLElement;

    if (community) {
      this.selectedCommunity = community;
    }

    // Remove seleção anterior, se houver
    if (this.selectedCommunityHtml) {
      this.selectedCommunityHtml.classList.remove('selected');
    }

    // Marca o item atual como selecionado
    this.selectedCommunityHtml = element.closest('.community-item');
    if (this.selectedCommunityHtml) {
      this.selectedCommunityHtml.classList.add('selected');
    }
  }

  confirmSelection(): void {
    // Se uma comunidade foi selecionada na lista
    if (this.selectedCommunity) {
      this.matDialog.close(this.selectedCommunity);
      return;
    }

    // Se não foi selecionada nenhuma comunidade, mas uma opção especial foi acionada (Criar ou Entrar)
    if (this.showHideCreateCommunity !== null) {
      this.matDialog.close('openAll');
      return;
    }

    // Se nada foi selecionado, não faz nada
  }

  cancelSelection(): void {
    this.matDialog.close(null);
  }

  hideShowCreateCommunity(): void {
    this.showHideCreateCommunity = true;
  }

  showCreateCommunity(): void {
    this.showHideCreateCommunity = false;
  }
}
