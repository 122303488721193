<div class="container-fluid" id="community-warnings">
  <h4 id="warning-title">&lt; Avisos</h4>
  <div id="community-warning-about">
    <h4>Sobre esta página</h4>
    <p>
      Esta é uma página dedicada a avisos e informações pertinentes referentes aos grupos. Aqui será postada todas e quaisquer informações
      que sejam relevantes para a manutenção da nossa comunidade.
    </p>
  </div>
  <div class="warning-posts">
    <section class="post-left">
      <div class="post-month">
        <span>Julho</span>
        <div class="line"></div>
      </div>
      <div class="card-item">
        <div *ngFor="let warning of warnings; let i = index">
          <div class="warning-item mb-3">
            <div class="warning-header">
              <div class="warning-header-left">
                <div class="warning-header-info">
                  <span class="warning-header-date">
                    {{ warning.dateHour }}
                  </span>
                  <span class="warning-header-title pointer">
                    {{ warning.title }}
                  </span>
                </div>
              </div>
            </div>
            <div class="warning-body">
              <p class="warning-text">
                {{ warning.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="post-month">
        <span>Junho</span>
        <div class="line"></div>
      </div>
      <div class="card-item">
        <div *ngFor="let warning of warnings; let i = index">
          <div class="warning-item mb-3">
            <div class="warning-header">
              <div class="warning-header-left">
                <div class="warning-header-info">
                  <span class="warning-header-date">
                    {{ warning.dateHour }}
                  </span>
                  <span class="warning-header-title pointer">
                    {{ warning.title }}
                  </span>
                </div>
              </div>
            </div>
            <div class="warning-body">
              <p class="warning-text">
                {{ warning.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="post-right">
      <div class="post-month">
        <span>Filtrar por:</span>
      </div>
      <div class="personalized-card mb-3">
        <h4 id="warning-title" class="mb-2 mt-0">Data de Avisos</h4>
        <div class="line mb-3"></div>
        <ngx-daterangepicker-material
          [locale]="calendarLocale"
          [showCancel]="true"
          (choosedDate)="onDateChosen($event)"></ngx-daterangepicker-material>
      </div>
    </section>
  </div>
</div>
