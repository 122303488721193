import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomCommunityPhotosService } from 'src/app/custom-community-photos/custom-community-photos.service';
import { EventsService } from 'src/app/events/events.service';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan, UserProfilePlan } from 'src/app/model/user-plan.model';
import { OpenEventService } from 'src/app/open-event.service';
import { OpenedCommunityService } from 'src/app/opened-community.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { CodigoVerificacaoComponent } from '../../core/components/codigo-verificacao/codigo-verificacao.component';
import { LoadingService } from '../../core/service/loading.service';
import { ToastService } from '../../core/service/toast.service';
import { CadastroStorageService } from '../cadastro/cadastro-storage.service';
import { ModalSelectCommunityComponent } from '../modal-select-community/modal-select-community.component';
import { CommunityVerificationsService } from './../../../events/community-verifications.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild(CodigoVerificacaoComponent) codigoVerificacao!: CodigoVerificacaoComponent;

  userEmail = new FormControl('', [Validators.required, Validators.email]);
  loading = false;
  chaveAcesso = '';
  codigoError = '';

  // Controle de logo e background
  customBg: string | null = null;
  customLogo: string | null = null;

  // Flag para controlar estados de tela
  foiEnviadoCodVerf: boolean = false;
  buttonLabelEntrar = 'Enviar código de verificação';

  // Comunidades
  myCommunity: SluperEvent | null = null;
  public myCommunities: SluperEvent[] = [];

  // Dados de perfil
  public userProfile: DocumentUserProfile | null = {} as DocumentUserProfile;
  userProfilePlan: IUserProfilePlan = {
    planName: 'default',
    profilePlanLimits: {
      contactsInclude: 0,
      socialLinksInclude: 0,
      linksInclude: 0,
      leadsViewContacts: 0,
      dashViews: false,
      dashAccessGraph: false,
      dashAccessedLinks: false,
      professionalView: false,
      eventsView: false,
      eventsIncludeQuantity: 0,
      connectContacts: false,
      includeEvents: false,
      connectContactsView: 0,
      participateInEvents: false,
      eventsParticipantsView: false
    }
  };

  constructor(
    private appNavigateService: AppNavigateService,
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private openedCommunityService: OpenedCommunityService,
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    private openEventService: OpenEventService,
    private communityVerificationsService: CommunityVerificationsService,
    private cadastroStorageService: CadastroStorageService,
    private customCommunityPhotosService: CustomCommunityPhotosService
  ) { }

  async ngOnInit() {
    // Observa alterações de logo e background
    this.customCommunityPhotosService.logo$.subscribe(logo => {
      this.customLogo = logo || null;
    });

    this.customCommunityPhotosService.background$.subscribe(background => {
      this.customBg = background || null;
    });

    // Verifica o parâmetro de comunidade
    this.route.params.subscribe(async (params) => {
      const communityId = params['parametro'];
      // Se communityId for um UUID, navegar para tela de ativação
      if (this.isUUID(communityId)) {
        this.appNavigateService.navegarChaveAtivacao(communityId);
        return;
      }

      try {
        this.loadingService.show('#fff');
        if (communityId) {

          if (this.openedCommunityService.getMyCommunity()
            && this.openedCommunityService.getMyCommunity() !== communityId) {
            this.openedCommunityService.clearAllData();
          }

          await this.setupCommunity(communityId);
        } else {
          // Não tem comunidade -> limpa dados de comunidade
          this.openedCommunityService.clearAllData();
          this.customCommunityPhotosService.clear();

          // Se já estiver autenticado, segue para usuário logado
          if (this.authService.isAuthenticated()) {
            await this.usuariologado();
          }
        }
      } catch (error) {
        console.error('Erro ao carregar os dados da comunidade:', error);
        this.toastService.showError('Ocorreu um erro ao carregar os dados da comunidade.');
        this.router.navigate(['/login']);
      } finally {
        this.loadingService.hide();
      }
    });
  }

  /**
   * Recebe um string e verifica se ele está no formato de UUID.
   */
  isUUID(value: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  }

  /**
   * Configura a comunidade (logo/background) de acordo com o parâmetro recebido
   * e se usuário já está logado ou não.
   */
  private async setupCommunity(communityId: string): Promise<void> {
    // Tenta obter a comunidade atual do serviço OpenedCommunityService
    const savedEvent = this.openedCommunityService.getMyCommunity();

    if (savedEvent) {
      this.myCommunity = savedEvent;
    } else {
      // Se não tiver salva, busca via serviço
      const eventUrl = await firstValueFrom(
        this.eventsService.communityByCommunityUrl(communityId)
      );
      if (eventUrl.body) {
        this.myCommunity = { ...eventUrl.body, id: eventUrl.body.eventId };
      }
    }

    // Seta logo/background personalizados
    this.customBg = this.myCommunity?.imageLink || null;
    this.customLogo = this.myCommunity?.communityLogo || null;
    this.customCommunityPhotosService.setLogo(this.customLogo ?? '');
    this.customCommunityPhotosService.setBackground(this.customBg ?? '');

    // Define a comunidade como "aberta"
    if (!communityId || communityId === 'undefined') {
      this.openedCommunityService.clearAllData();
    } else {
      this.openedCommunityService.setCommunity(communityId);
      this.openedCommunityService.setMyCommunity(this.myCommunity!);
    }

    // Se usuário já está logado, chama lógica de pós-login
    if (this.authService.isAuthenticated()) {
      await this.usuariologado();
    }
    // Caso contrário, apenas permanece na tela de login
  }

  /**
   * Chamada ao clicar no botão principal ("Enviar código" ou "Entrar").
   */
  entrar() {
    if (!this.foiEnviadoCodVerf && this.userEmail.valid) {
      this.validarEmail();
    } else {
      this.validarCodigo();
    }
  }

  /**
   * Solicita o envio do código de verificação para o email digitado.
   */
  validarEmail() {
    this.loadingService.show('#fff');
    this.loginService.login(String(this.userEmail.value)).subscribe({
      next: (response) => {
        if (response.status === 200 && response.body?.status === 'SUCCESS') {
          this.foiEnviadoCodVerf = true;
          this.buttonLabelEntrar = 'Entrar';
          this.toastService.showInfo('Código de verificação foi enviado para o seu email');
        }
        this.loadingService.hide();
      },
      error: (err) => {
        this.loadingService.hide();
        this.toastService.showError('O Email inserido não é válido');
      }
    });
  }

  /**
   * Valida o código de verificação digitado.
   */
  async validarCodigo() {
    this.loadingService.show('#fff');
    this.loginService.validateEmailKey(
      String(this.codigoVerificacao.getCodigoVerificacao()).toUpperCase(),
      this.userEmail.value
    ).subscribe({
      next: async (response) => {
        try {
          const chaveValida = response.body?.status === 'SUCCESS' ? response.body?.message : '';
          this.authService.login(chaveValida);

          await this.usuariologado();
        } catch (err) {
          console.error('Error during validarCodigo:', err);
          this.toastService.showError('Ocorreu um erro durante a validação.');
        } finally {
          this.loadingService.hide();
        }
      },
      error: () => {
        this.loadingService.hide();
        this.toastService.showError('Código de verificação inválido');
      }
    });
  }

  /**
   * Permite reenviar o código de verificação.
   */
  reenviarCodigo() {
    this.validarEmail();
  }

  /**
   * Navega para a página de cadastro.
   */
  navegarCadastro() {
    this.appNavigateService.navegarCadastro();
  }

  /**
   * Fluxo executado quando o usuário já está autenticado.
   * Verifica comunidades que o usuário participa, plano de assinatura,
   * e redireciona conforme regras de negócio.
   */
  async usuariologado() {
    if (!this.authService.isAuthenticated()) {
      console.error("Usuário não autenticado.");
      return;
    }

    try {
      this.loadingService.show('#fff');

      // 1. Busca userProfile
      const userProfile = await this.communityVerificationsService.getUserProfile();
      this.userProfile = userProfile?.documentUserProfile || null;

      // 2. Busca todas as comunidades que o usuário participa
      const allUserCompanies = await this.communityVerificationsService.getAllCompanies();
      this.myCommunities = allUserCompanies.filter(ev => ev.type === 'COMUNIDADE');

      // 3. Busca o plano do usuário
      const userPlan: UserProfilePlan = await this.communityVerificationsService.getUserPlan();
      const planName = userPlan.planName;

      // 4. Verifica se o usuário é dono de comunidade
      const userIsCommunityOwner = !this.myCommunities.some(
        ev => ev.organizer.idUser === this.userProfile?.idUser
      );

      // 5. Verifica se há convite (por ex. via openEventService)
      const invite = this.openEventService.getId();
      const inviteType = this.openEventService.getType();

      // Se `this.myCommunity` foi setada durante o login (parâmetro de rota)
      if (this.myCommunity) {
        const myCommunityData = await firstValueFrom(
          this.eventsService.eventById(this.myCommunity?.id!)
        );

        // Verifica se o usuário já é participante dessa comunidade
        const isAlreadyParticipant = myCommunityData.body?.participants
          ?.some(part => part.idUser === this.userProfile?.idUser);

        // Caso o usuário participe ou tenha um invite, segue normalmente.
        if (isAlreadyParticipant || (invite && invite.length)) {
          // Garante dados atualizados (eventUrl, name, etc.)
          if (!this.myCommunity.name && myCommunityData.body?.event) {
            this.myCommunity = myCommunityData.body.event;
            this.openedCommunityService.setMyCommunity(this.myCommunity);
            this.openedCommunityService.setCommunity(this.myCommunity?.eventUrl!);
          }
          this.verifyUserParticipeAndRedirect(this.myCommunity?.eventUrl!);
          return;
        } else {
          // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          // Se chegou aqui, o usuário NÃO participa da comunidade do parâmetro.
          // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

          // 1. Exiba a mensagem
          this.toastService.showInfo('Você não participa desta comunidade.');
          this.openedCommunityService.clearAllData();
          this.customCommunityPhotosService.clear();
          this.router.navigateByUrl('login');
          // 2. Abre modal para selecionar outra comunidade ou "entrar sem comunidade"
          // this.openModalSelectCommunity(userIsCommunityOwner, planName);

          return; // sai da função, pois já abrimos o modal
        }
      }

      // Se NÃO há this.myCommunity ou não veio parâmetro de rota
      // então tratamos os cenários de zero, uma ou múltiplas comunidades
      await this.handleMultipleOrNoCommunities(
        allUserCompanies,
        userPlan,
        userIsCommunityOwner
      );

    } catch (error) {
      console.error("Erro principal no método usuário logado:", error);
    } finally {
      this.loadingService.hide();
    }
  }


  /**
   * Trata casos de múltiplas comunidades, nenhuma comunidade e criação de comunidade.
   */
  private async handleMultipleOrNoCommunities(
    allUserCompanies: SluperEvent[],
    userPlan: UserProfilePlan,
    userIsCommunityOwner: boolean
  ): Promise<void> {
    // 1. Sem nenhuma comunidade
    if (allUserCompanies.length === 0) {
      if (userPlan.planName === "Plano Corporativo" && userIsCommunityOwner) {
        this.openModalSelectCommunity(userIsCommunityOwner, userPlan.planName);
      } else {
        // Redireciona para "comunidade" sem nada
        this.openedCommunityService.clearAllData();
        await this.router.navigateByUrl('comunidade');
      }
      return;
    }

    // 2. Exatamente uma comunidade
    // if (allUserCompanies.length === 1) {
    //   this.myCommunity = allUserCompanies[0];
    //   this.openedCommunityService.setCommunity(this.myCommunity!.eventUrl!);
    //   this.openedCommunityService.setMyCommunity(this.myCommunity!);
    //   this.verifyUserParticipeAndRedirect(this.myCommunity?.eventUrl!);
    //   return;
    // }

    // 3. Mais de uma comunidade -> abre modal
    this.openModalSelectCommunity(userIsCommunityOwner, userPlan.planName);
  }

  /**
   * Redireciona para a comunidade (eventUrl).
   */
  verifyUserParticipeAndRedirect(eventUrl: string) {
    this.router.navigateByUrl('comunidade/' + eventUrl);
  }

  /**
   * Abre o modal para seleção de comunidades (e criação de nova comunidade, caso permitido).
   */
  openModalSelectCommunity(userIsCommunityOwner: boolean, planName: string) {
    const dialogRef = this.dialog.open(ModalSelectCommunityComponent, {
      data: {
        communities: this.myCommunities,
        isCommunityOwner: planName === "Plano Corporativo" && userIsCommunityOwner
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      (result: SluperEvent | string) => {
        if (typeof result === 'string') {
          // Caso clique em "Fechar" ou opção que não retorne um SluperEvent
          this.openedCommunityService.clearAllData();
          this.router.navigateByUrl('comunidade');
          return;
        }

        // Se for uma comunidade de fato
        if (!result.eventUrl) {
          this.toastService.showInfo('Essa comunidade ainda não cadastrou o seu link de comunidade');
          return;
        }

        // Atualiza dados da comunidade selecionada
        this.loading = true;
        this.myCommunity = result;
        this.openedCommunityService.setCommunity(result.eventUrl!);
        this.openedCommunityService.setMyCommunity(result);

        this.customBg = result.imageLink || null;
        this.customLogo = result.communityLogo || null;
        this.customCommunityPhotosService.setLogo(this.customLogo ?? '');
        this.customCommunityPhotosService.setBackground(this.customBg ?? '');

        setTimeout(() => {
          this.router.navigateByUrl('comunidade/' + this.myCommunity?.eventUrl);
          this.loadingService.hide();
          this.loading = false;
        }, 1000);
      },
      error => {
        this.loading = false;
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

}
