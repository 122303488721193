<!-- <div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf=" loading"></app-loading-spinner>



<section id="delete-side-bar-item">
    <h4 *ngIf="data.type === 'post'">Deletar post</h4>
    <h4 *ngIf="data.type === 'comment'">Deletar comentário</h4>

    <span *ngIf="data.type === 'post'">Atenção! Você está prestes a deletar um post.
        Todas as publicações e mídias serão perdidas, você tem certeza que deseja deletar este post?</span>

    <span *ngIf="data.type === 'comment'">Atenção! Você está prestes a deletar um comentário.
        Você tem certeza que deseja deletar este comentário?</span>


    <div class="button-container">
        <button type="button" class="item-cancel-button" (click)="closeModal()">Cancelar</button>
        <button type="button" class="item-delete-button" (click)="deleteOption()">Deletar</button>
    </div>
</section>