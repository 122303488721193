import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoadingService } from '../new-sluper/core/service/loading.service';
import { OpenedCommunityService } from '../opened-community.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticated = false;

  // Subject para notificar quando ocorre erro 401
  private unauthorizedSubject = new Subject<void>();
  public unauthorized$ = this.unauthorizedSubject.asObservable();

  constructor(
    private router: Router,
    private openedCommunityService: OpenedCommunityService,
    private loadingService: LoadingService
  ) {
    this.authenticated = !!localStorage.getItem('authToken');
  }

  login(token: string, redirect: boolean = true): void {
    this.authenticated = true;
    localStorage.setItem('authToken', token);
    if (!redirect) {
      setTimeout(() => {
        this.loadingService.hide();
      }, 1000);
      return
    }

    // Verifica se há uma comunidade ativa

    const communityId = this.openedCommunityService.getCommunity();
    if (communityId) {
      this.router.navigate(['/login', communityId]); // Navega para login/comunidadeID
    } else {
      this.router.navigate(['/login']); // Navega para login
    }
    setTimeout(() => {
      this.loadingService.hide();
    }, 1000);
  }

  logout(): void {
    const communityId = this.openedCommunityService.getCommunity();
    this.authenticated = false;
    localStorage.removeItem('authToken');
    this.openedCommunityService.setCommunity(null); // Remove a comunidade ativa
    if (communityId) {
      this.router.navigate(['/login/', communityId]); // Navega para login/comunidadeID
    } else {
      this.router.navigate(['/login']); // Navega para login
    }
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  // Método para emitir o evento de erro 401
  notifyUnauthorized(): void {
    this.unauthorizedSubject.next();
  }
}
