import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { PostsService } from '../posts.service';

@Component({
  selector: 'app-modal-delete-post',
  templateUrl: './modal-delete-post.component.html',
  styleUrls: ['./modal-delete-post.component.css']
})
export class ModalDeletePostComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  public loading = false;

  /**
   * Recebe via data:
   *  - id: número do post/comentário a ser deletado
   *  - type: 'post' ou 'comment'
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; type: 'post' | 'comment' },
    private dialogRef: MatDialogRef<ModalDeletePostComponent>,
    private postsService: PostsService,
    private toastrService: ToastService
  ) { }

  ngOnInit(): void { }

  /**
   * Fecha a modal retornando um objeto com a resposta de sucesso ou não.
   * Assim, o componente que abriu a modal consegue reagir (remover o item localmente etc.).
   */
  closeModal(success: boolean = false): void {
    this.dialogRef.close({ success });
  }

  /**
   * Método de ação principal no HTML: chama o deletePost() ou deleteComment()
   * de acordo com o tipo recebido em data.
   */
  async deleteOption(): Promise<void> {
    if (this.data.type === 'post') {
      await this.deletePost();
    } else {
      await this.deleteComment();
    }
  }

  private async deletePost(): Promise<void> {
    this.loading = true;
    try {
      // Chama a API para deletar o post
      await firstValueFrom(this.postsService.deletePost(this.data.id.toString()));
      this.toastrService.show('Post deletado com sucesso.', 'success');
      // Fecha a modal devolvendo { success: true }
      this.closeModal(true);
    } catch (error) {
      console.error('Erro ao deletar post', error);
      this.toastrService.show('Ocorreu um erro ao tentar deletar o post. Tente novamente mais tarde.', 'error');
    } finally {
      this.loading = false;
    }
  }

  private async deleteComment(): Promise<void> {
    this.loading = true;
    try {
      // Chama a API para deletar o comentário
      await firstValueFrom(this.postsService.deleteComment(this.data.id.toString()));
      this.toastrService.show('Comentário deletado com sucesso.', 'success');
      // Fecha a modal devolvendo { success: true }
      this.closeModal(true);
    } catch (error) {
      console.error('Erro ao deletar comentário', error);
      this.toastrService.show('Ocorreu um erro ao tentar deletar o comentário. Tente novamente mais tarde.', 'error');
    } finally {
      this.loading = false;
    }
  }
}
