import { Component } from '@angular/core';

@Component({
  selector: 'app-community-finance',
  templateUrl: './community-finance.component.html',
  styleUrls: ['./community-finance.component.css']
})
export class CommunityFinanceComponent {

}
