<!-- <div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf=" loading"></app-loading-spinner>



<section id="delete-side-bar-item">
    <h4>{{ data.modalType == 'category' ? 'Deletar Categoria' : 'Deletar Canal' }}</h4>

    <span *ngIf="data.modalType == 'channel'">Atenção! Você está prestes a deletar um Canal.
        Todas as publicações e mídias serão perdidas, você tem certeza que deseja deletar este canal?</span>

    <span *ngIf="data.modalType == 'category'">Atenção! Você está prestes a deletar uma Categoria.
        Todos os canais serão mantidos, porém sem categoria especifica. Deseja prosseguir?</span>

    <div class="button-container">
        <button type="button" class="item-cancel-button" (click)="closeModal()">Cancelar</button>
        <button type="button" class="item-delete-button" (click)="deleteOption()">Deletar</button>
    </div>
</section>