import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { firstValueFrom } from 'rxjs';
import { CustomCommunityPhotosService } from 'src/app/custom-community-photos/custom-community-photos.service';
import { EventsService } from 'src/app/events/events.service';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { IFormattedProfileData } from 'src/app/model/profile.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { DocumentUserCSS } from 'src/app/new-sluper/core/model/document-user-css.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';

type ProfileImagesTypes = 'profile' | 'calling-card-background' | 'background'
interface ISelectedProfile {
  indicator: number;
  data: IFormattedProfileData;
}
@Component({
  selector: 'app-modal-background-color',
  templateUrl: './modal-background-color.component.html',
  styleUrls: ['./modal-background-color.component.css']
})
export class ModalBackgroundColorComponent implements OnInit {
  @ViewChild('imageSelectionModal') imageSelectionModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  public loading = false
  public defaultCallingCardImage = '/assets/img/imagem_fundo_preview.png';
  public defaultUnknownImage = '/assets/img/imagem_perfil.png';
  public communityItem: SluperEvent = {
    id: '',
    name: '',
    website: '',
    type: '',
    country: '',
    state: '',
    city: '',
    latitude: '',
    longitude: '',
    address: '',
    startDate: '',
    endDate: '',
    numberOfParticipants: 0,
    isPublic: false,
    description: '',
    imageLink: '',
    organizer: {
      idUser: '',
      name: '',
      uriImageProfile: ''
    },
    requiresConfirmation: false,
    guests: [],
    about: '',
    useCommunityLogo: false,
    communityLogo: null
  };
  public userProfile: DocumentUserProfile = {
    idUser: '',
    name: '',
    bio: '',
    publish: false,
    showImageProfile: false,
    parent: '',
    listURI: [],
    uriImageProfile: '',
    uriImageBackground: '',
    listSocialNetwork: [],
    listButtons: [],
    listUriImages: [],
    header: {
      text: '',
      visible: false
    },
    listProfile: [],
    listContacts: [],
    networkingControl: {
      used: 0,
      max: 0,
    }

  }
  public imageItems: {
    logo: string, logoEvent: string | null, logoBlob: File | null,
    cover: string, coverEvent: string | null, coverBlob: File | null,
    temporary: string, temporaryEvent: string | null, temporaryBlob: File | null,
  } = {
      logo: '',
      logoEvent: '',
      logoBlob: null,
      cover: '',
      coverEvent: '',
      coverBlob: null,
      temporary: '',
      temporaryEvent: '',
      temporaryBlob: null
    }
  imageConfig: { isSelectionImageModalOpen: boolean, currentImageType: ProfileImagesTypes, cropMode: boolean, coverUpdated: boolean, logoUpdated: boolean } = {
    isSelectionImageModalOpen: false,
    currentImageType: 'calling-card-background',
    cropMode: false,
    coverUpdated: false,
    logoUpdated: false
  }
  public logoConfig = {
    showImage: true,
    showTooltip: false
  }

  formErrors = {
    image: {
      valid: true,
      message: ''
    },
    imageLogo: {
      valid: true,
      message: ''
    },
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      userProfile: DocumentUserProfile | null,
      userProfilePlan: IUserProfilePlan,
      userCss: DocumentUserCSS,
      communityItem: SluperEvent
    },
    private modalService: NgbModal,
    private eventsService: EventsService,
    private userProfileService: UserProfileService,
    private matDialogRefModalBackground: MatDialogRef<ModalBackgroundColorComponent>,
    private toastrService: ToastService,
    private customCommunityPhotosService: CustomCommunityPhotosService

  ) { }

  ngOnInit(): void {
    this.communityItem = this.data.communityItem
    this.imageItems.cover = this.communityItem.imageLink
    this.imageItems.logo = this.communityItem.communityLogo || ''
    this.userProfile = this.data.userProfile!
  }


  openImageSelectionModal(imageType: ProfileImagesTypes): void {
    // this.imageConfig.logoUpdated = false
    // this.imageConfig.coverUpdated = false
    this.imageConfig.isSelectionImageModalOpen = true;
    this.imageConfig.currentImageType = imageType;
    this.imageConfig.cropMode = false;
    imageType === 'calling-card-background' ? this.imageItems.coverEvent = null : this.imageItems.coverEvent = null

    const modalRef = this.modalService.open(this.imageSelectionModal, {
      size: 'lg',
      centered: true,
    });

    modalRef.result
      .then(() => {
        this.imageConfig.isSelectionImageModalOpen = false;
      })
      .catch(() => {
        this.imageConfig.isSelectionImageModalOpen = false;
      });
  }

  fileChangeEvent(event: any, minWidth: number, minHeight: number): void {
    const file: File = event.target.files[0];
    if (!file) {
      console.error('Nenhum arquivo selecionado');
      return; // Garantir que há um arquivo
    }

    const maxSizeInBytes = 2 * 1024 * 1024;

    // Validação do tamanho do arquivo
    if (file.size > maxSizeInBytes) {

      this.toastrService.show('Tamanho do arquivo é maior do que o permitido. Por favor, selecione uma imagem de até 2MB.', 'warning');
      this.removeSelectedImage();
      this.modalService.dismissAll();
      console.error('Tamanho do arquivo é maior do que o permitido. Por favor, selecione uma imagem de até 2MB.');

      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result as string;

      image.onload = () => {
        if (image.width < minWidth || image.height < minHeight) {
          this.toastrService.show(`A imagem precisa ter no mínimo ${minWidth}x${minHeight} pixels.`, 'warning');
          console.error(`A imagem precisa ter no mínimo ${minWidth}x${minHeight} pixels.`);

          this.removeSelectedImage();
          this.modalService.dismissAll();
          return;
        }

        // Configuração da imagem válida
        this.imageConfig.cropMode = true;
        this.imageItems.temporaryEvent = event;
        this.imageConfig.coverUpdated = true
      };
    };

    reader.readAsDataURL(file);
  }


  fileChangeEventLogo(event: any, minWidth: number, minHeight: number): void {
    const file: File = event.target.files[0];
    if (!file) {
      console.error('Nenhum arquivo selecionado');
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      this.toastrService.show('Tamanho do arquivo é maior do que o permitido. Por favor, selecione uma imagem de até 2MB.', 'warning');
      this.removeSelectedLogoImage();
      this.modalService.dismissAll();
      console.error('Tamanho do arquivo é maior do que o permitido. Por favor, selecione uma imagem de até 2MB.');

      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result as string;

      image.onload = () => {
        this.imageConfig.cropMode = true;
        this.imageItems.temporaryEvent = event;
        this.imageConfig.logoUpdated = true

      };
    };

    reader.readAsDataURL(file);
  }



  imageCropped(event: ImageCroppedEvent) {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imageItems.temporary = reader.result as string
      };

      reader.readAsDataURL(blob);
    }
  }

  confirmImageSelection(): void {
    this.imageConfig.currentImageType === 'calling-card-background' ? this.imageItems.cover = this.imageItems.temporary : this.imageItems.logo = this.imageItems.temporary

    const croppedImage = this.imageConfig.currentImageType === 'calling-card-background'
      ? this.imageItems.cover
      : this.imageItems.logo;

    if (
      !croppedImage ||
      (this.imageConfig.currentImageType === 'calling-card-background' && !this.imageConfig.coverUpdated) ||
      (this.imageConfig.currentImageType === 'profile' && !this.imageConfig.logoUpdated)
    ) {
      return;
    }

    try {
      const imageTypeToFileNamePrefix: { [key in ProfileImagesTypes]: string } = {
        'profile': `profile_image_`,
        'calling-card-background': 'background_image_',
        'background': 'external_background_image_'
      };

      const fileNamePrefix = imageTypeToFileNamePrefix[this.imageConfig.currentImageType];
      if (!fileNamePrefix) {
        console.warn(`Unknown image type: ${this.imageConfig.currentImageType}`);
        return;
      }

      const currentTime = Date.now();
      const fileName = `${fileNamePrefix}${currentTime}.png`; // Alterado para PNG
      const blob = this.dataURItoBlob(croppedImage, 'image/png'); // Passar tipo 'image/png'
      const blobFile = new File([blob], fileName, {
        type: 'image/png', // Alterado para PNG
        lastModified: currentTime,
      });
      this.updateImageState(this.imageConfig.currentImageType, blobFile, croppedImage);
      this.modalService.dismissAll();
    } catch (e) {
      this.toastrService.show('Ocorreu um erro ao confirmar a seleção de imagem, tente novamente mais tarde.', 'error');
    }
  }

  dataURItoBlob(dataURI: string, mimeType: string = 'image/png'): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  }


  private updateImageState(imageType: ProfileImagesTypes, blobFile: File, croppedImage: string): void {
    this.imageConfig.currentImageType === 'calling-card-background' ? this.imageItems.coverBlob = blobFile : this.imageItems.logoBlob = blobFile
    this.imageConfig.currentImageType === 'calling-card-background' ? this.imageConfig.coverUpdated = true : this.imageConfig.logoUpdated = true

    // this.uploadedBackgroundImage = blobFile;
    // this.communityBackground = croppedImage;
    // this.imageBackgroundChanged = true;
  }

  resetFormErrors() {
    this.formErrors = {
      image: {
        valid: true,
        message: ''
      },
      imageLogo: {
        valid: true,
        message: ''
      },
    }
  }

  private validateForm() {
    this.resetFormErrors();
    if (!this.imageItems.cover || !this.imageItems.cover.length) {
      this.formErrors.image = {
        valid: false,
        message: 'Imagem obrigatória'
      };
    }
    if (!this.imageItems.logo || !this.imageItems.logo.length) {
      this.formErrors.imageLogo = {
        valid: false,
        message: 'Imagem obrigatória'
      };
    }
  }

  async updateImages() {
    try {
      this.loading = true;
      this.validateForm()
      if (Object.values(this.formErrors).some((error) => error.valid === false)) {
        this.showAlert('error', 'Erro ao criar comunidade, verifique os campos obrigatórios.');
        this.loading = false;
        return;
      }
      const { currentImageType, coverUpdated, logoUpdated } = this.imageConfig;

      if (coverUpdated && this.imageItems.coverBlob || logoUpdated && this.imageItems.logoBlob) {
        if (coverUpdated) {
          await this.updateCommunityCover();
        }
        if (logoUpdated) {
          await this.updateUserProfile();
        }
      } else {
        this.closeModalBackground(false);
        this.loading = false;
        return;
      }
      this.showAlert('success', 'Atualização concluída com sucesso.');
      setTimeout(() => {
        this.closeModalBackground(true);
        this.loading = false;
      }, 2000);
    } catch (error) {
      this.loading = false;
      this.showAlert('error', 'Erro ao atualizar imagem. Tente novamente.');
    }
  }

  async updateCommunityCover() {
    const { coverUpdated } = this.imageConfig;

    if (!coverUpdated) {
      this.showAlert('error', 'Nenhuma atualização detectada na capa da comunidade.');
      return;
    }

    if (!this.userProfile) {
      this.showAlert('error', 'Erro ao atualizar a capa: usuário não encontrado.');
      return;
    }

    try {
      const eventFile = this.imageItems.coverBlob;
      const uploadImageResponse = await firstValueFrom(
        this.eventsService.saveEventImage(
          this.communityItem.id,
          `event_${this.communityItem.id}`,
          eventFile!
        )
      );
      this.communityItem.imageLink = uploadImageResponse;
      this.customCommunityPhotosService.setBackground(uploadImageResponse);
      // this.showAlert('success', 'Capa da comunidade atualizada com sucesso.');
    } catch (error) {
      console.error('Erro ao atualizar a capa da comunidade:', error);
      this.showAlert('error', 'Erro ao atualizar a capa da comunidade. Tente novamente.');
    }
  }


  async updateUserProfile() {
    const { logoUpdated } = this.imageConfig;

    if (!logoUpdated) {
      this.showAlert('error', 'Nenhuma atualização detectada no logo.');
      return;
    }

    try {
      const response = await firstValueFrom(
        this.eventsService.saveCommunityImageLogo(
          this.communityItem.id,
          this.imageItems.logoBlob!
        )
      );
      console.log('response', response);
      this.customCommunityPhotosService.setLogo(response.communityLogo);
      // this.showAlert('success', 'Logo atualizado com sucesso.');
    } catch (error: any) {
      console.error('Erro ao atualizar o logo:', error);
      if (error.status === 403) {
        this.showAlert('error', 'Permissão negada para atualizar o logo.');
      } else {
        this.showAlert('error', 'Erro ao atualizar o logo. Entre em contato com o administrador.');
      }
      throw error;
    }
  }


  private showAlert(type: 'success' | 'error', message: string) {
    this.toastrService.show(message, type);
  }
  closeModalBackground(response: boolean) {
    this.matDialogRefModalBackground.close(response)
  }

  toggleProfileImageVisible(event: any) {
    event.stopPropagation();
    this.logoConfig.showImage = !this.logoConfig.showImage;
  }

  toggleTooltipProfile(event: Event) {
    event.stopPropagation();
    this.logoConfig.showTooltip = !this.logoConfig.showTooltip;
  }

  async deleteLogoImage(event: Event) {
    event.stopPropagation();
    this.imageItems.logoEvent = null
    this.imageItems.logoBlob = null
    this.imageConfig.logoUpdated = false
    if (this.imageItems.logo && this.imageItems.logo !== this.communityItem.communityLogo) {
      this.imageItems.logo = this.communityItem.communityLogo || ''
    } else {
      try {
        this.imageItems.logo = '';
        // await firstValueFrom(this.eventsService.saveCommunityImageLogo(
        //   this.communityItem.id,
        //   null,
        // ));
        // this.showAlert('success', 'Perfil salvo com sucesso.');
        this.showAlert('success', 'Alteração no backend para permitir remover a imagem');
      } catch (error) {
        this.showAlert('error', 'Erro ao salvar o perfil. Contacte o administrador.');
      }
    }

  }


  removeSelectedImage() {
    this.imageConfig.cropMode = false;
    this.imageItems.cover = '';
    this.imageItems.coverEvent = null;
    this.imageConfig.coverUpdated = false
  }
  removeSelectedLogoImage() {
    this.imageConfig.cropMode = false;
    this.imageItems.logo = '';
    this.imageItems.logoEvent = null;
    this.imageConfig.logoUpdated = false
  }

}
