<div class="community-container">
    <h4 class="community-title">Selecione uma comunidade</h4>
    <div class="community-list">
        <div *ngIf="isCommunityOwner" class="community-item" (click)="selectCommunity($event); showCreateCommunity()">
            <img src="/assets/img/Marca Sluper Colorida.svg" alt="Community Photo" class="community-photo" />
            <span class="community-name">Criar uma comunidade</span>
        </div>
        <div *ngIf="isUserEventInvited" class="community-item"
            (click)="selectCommunity($event); hideShowCreateCommunity()">
            <img src="/assets/img/Marca Sluper Colorida.svg" alt="Community Photo" class="community-photo" />
            <span class="community-name">Entrar</span>
        </div>
        <div *ngFor="let community of myCommunities" class="community-item"
            (click)="selectCommunity($event, community)">
            <img [src]="community.imageLink || ''" alt="Community Photo" class="community-photo" />
            <span class="community-name">{{ community.name }}</span>
        </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
        <button (click)="cancelSelection()" style="border: 1px solid red" *ngIf="showCancelButton"
            class="select-button-cancel">
            Cancelar
        </button>
        <button class="select-button" (click)="confirmSelection()">Selecionar</button>
    </div>
</div>