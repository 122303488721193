import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { Post } from '../../post/posts.service';

@Component({
  selector: 'app-community-favorites',
  templateUrl: './community-favorites.component.html',
  styleUrls: ['./community-favorites.component.css']
})
export class CommunityFavoritesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() posts: Post[] = [];
  @Input() isUserCommunityOwner: boolean = false
  @Input() userProfile: DocumentUserProfile | undefined
  @Input() communityItem!: SluperEvent
  @Input() selectedChannel!: ChannelInterface | null
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() participants: EventParticipant[] = [];
  private buttonClickListener: any;
  selectedRange: any = null;
  calendarLocale = {
    applyLabel: 'Aplicar',
    cancelLabel: 'Limpar',
    clearLabel: 'Limpar',
    customRangeLabel: 'Intervalo Personalizado',
  };

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.addButtonClickListener();
  }

  addButtonClickListener(): void {
    let button = this.el.nativeElement.querySelector('.btn.btn-default');

    if (button) {
      this.buttonClickListener = this.renderer.listen(button, 'click', () => {
        this.handleClearDate();
      });
    } else {
      setTimeout(() => {
        this.addButtonClickListener();
      }, 1000); // Espera 1 segundo antes de tentar novamente
    }
  }

  ngOnDestroy(): void {
    if (this.buttonClickListener) {
      this.buttonClickListener();
    }
  }

  handleClearDate(): void {
    this.selectedRange = null;
    // console.log('Calendário resetado.');
  }


  onDateChosen(event: any) {
    if (!event.startDate || !event.endDate) {
      this.handleClearDate();
    } else {
      const startDate = event.startDate.format('YYYY-MM-DD');
      const endDate = event.endDate.format('YYYY-MM-DD');

      // console.log('Start Date:', startDate);
      // console.log('End Date:', endDate);
    }
  }
}
