import { Component, Input, OnInit } from '@angular/core';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';

@Component({
  selector: 'app-community-settings',
  templateUrl: './community-settings.component.html',
  styleUrls: ['./community-settings.component.css']
})
export class CommunitySettingsComponent implements OnInit {
  @Input() isUserCommunityOwner: boolean = false
  @Input() userProfile: DocumentUserProfile | undefined
  @Input() communityItem!: SluperEvent
  @Input() userProfilePlan!: IUserProfilePlan;

  public selectedTab: 'community-information' | 'community-members' | 'community-channels-categories' | 'community-posts' = 'community-members';
  constructor() { }

  ngOnInit(): void {
  }


  public changeSelectedTab(tab: 'community-information' | 'community-members' | 'community-channels-categories' | 'community-posts') {
    this.selectedTab = tab;
  }
}
