import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { DocumentUser } from 'src/app/model/document-user.model';
import { IEventWithParticipationStatus, SluperEvent } from 'src/app/model/event.model';
import { IDocumentPreferences } from 'src/app/model/preferences.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { IUserProfileWithCSS } from 'src/app/model/user-profile-css.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { ModalSelectCommunityComponent } from 'src/app/new-sluper/public/modal-select-community/modal-select-community.component';
import { OpenedCommunityService } from 'src/app/opened-community.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { LoadingService } from './../../new-sluper/core/service/loading.service';

@Component({
  selector: 'app-events-header',
  templateUrl: './events-header.component.html',
  styleUrls: ['./events-header.component.css']
})
export class EventsHeaderComponent implements OnInit {
  @Output() refreshData = new EventEmitter();
  @Output() createEvent = new EventEmitter();
  @Output() createCommunity = new EventEmitter();
  @Output() redirectToSettings = new EventEmitter<string | any>();

  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'error';
  }>();
  @Output() openCommunityMembersModal = new EventEmitter<string>();
  // @Output() filterMembersCommunity = new EventEmitter();

  @Input() userProfile!: DocumentUserProfile | undefined;
  @Input() documentUser!: DocumentUser | undefined;
  @Input() userCss!: any | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() preferencesList!: IDocumentPreferences[];
  @Input() occupationsList!: IDocumentPreferences[];
  @Input() communityItem!: SluperEvent
  @Input() nextEvents!: SluperEvent[]
  @Input() nextCommunitys!: IEventWithParticipationStatus[]
  @Input() userConnections!: Connection[]
  @Input() allowedToCreateCommunity: boolean | undefined = false
  @Input() allowedToCreateEvent: boolean | undefined = false
  @Input() userProfileComplete: IUserProfileWithCSS | null = null;
  showCompleteDocument = false
  private userCanCreate = false //Verificar se é premium
  userPreferences: IDocumentPreferences[] = []
  selectedInterests: IDocumentPreferences[] = [];
  selectedOccupations: number[] = [];
  selectedPreferences: number[] = [];
  public message1: string = '';
  public message2: string = '';

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private router: Router,
    private toastrService: ToastService,
    private loadingService: LoadingService,
    private openedCommunityService: OpenedCommunityService,
    private userProfileService: UserProfileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userCanCreate = this.userProfilePlan?.planName === "Plano Corporativo";
    this.message1 = 'Complete o seu perfil pessoal';
    this.message2 = ' Crie uma Bio para se apresentar';
    // if(!this.userProfileComplete?.documentUserProfile.bio) {
    //   this.message = 'Crie uma Bio da sua empresa';
    // } else if (!this.userProfileComplete?.documentUserProfile.uriImageProfile) {
    //   this.message = 'Adicione uma imagem de perfil';
    // } else if (!this.userProfileComplete?.documentUserProfile.uriImageBackground) {
    //   this.message = 'Adicione uma imagem de background';
    // }
    await this.getUserById();
    await this.getUserPreferences();

  }

  async getUserPreferences() {
    if (this.userProfile) {
      this.userProfileService
        .getUserPreferences(this.userProfile.idUser)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
        .subscribe({
          next: (res: IDocumentPreferences[]) => {
            this.selectedOccupations = res.filter(up => up.type === 'AREA_ATUACAO').map(up => up.id);
            this.selectedPreferences = res.filter(up => up.type === 'INTERESSE').map(up => up.id);
            this.verifyUserNeedsToComplete()
          },
          error: (res) => console.error(res),
        });
    }
  }

  async getUserById() {
    this.userProfileService
      .userById(null)
      .pipe(
        filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
        map((response: HttpResponse<IUserProfileWithCSS>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userProfileComplete = res;
        },
        error: (res) => console.error(res),
      });
  }

  verifyUserNeedsToComplete() {
    if (
      !this.userProfileComplete?.documentUserProfile?.listURI?.length ||
      !this.userProfileComplete?.documentUserProfile?.presentation?.length ||
      !this.userProfileComplete?.documentUser.personal?.name?.trim()?.length ||
      !this.userProfileComplete?.documentUser.personal?.lastName?.trim()?.length ||
      !this.userProfileComplete?.documentUser?.email?.trim()?.length ||
      !this.userProfileComplete?.documentUser.personal?.birthdate?.toString()?.trim()?.length ||
      !this.userProfileComplete?.documentUser.personal?.country?.trim()?.length ||
      !this.userProfileComplete?.documentUser.personal?.phone.ddi?.length ||
      !this.userProfileComplete?.documentUser.personal?.phone.phoneNumber?.length ||
      !this.userProfileComplete?.documentUser.personal?.phone.siglaPais?.length ||
      !this.userProfileComplete?.documentUser.personal?.cep?.trim()?.length ||
      !this.selectedOccupations.length ||
      !this.selectedPreferences.length
    ) {
      this.showCompleteDocument = true;
    }
  }

  getFormattedDate(date: string): string {
    if (!date) return 'Não há próximo evento';
    return formatDate(date, 'dd \'de\' MMMM', 'pt-BR');
  }

  redirectToSluper() {
    // window.open('https://sluper.digital', '_blank');
    window.open('https://api.whatsapp.com/send/?phone=5531995240115&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+as+solu%C3%A7%C3%B5es+da+Sluper+Digital.&type=phone_number&app_absent=0', '_blank');
  }

  verifyUserCanCreate(): boolean {
    return this.userCanCreate
  }

  createItem(type: 'event' | 'community') {
    if (type === 'event') {
      this.createEvent.emit()
    } else {
      this.createCommunity.emit()
    }
  }

  handleOpenCommunityMembersModal() {
    this.openCommunityMembersModal.emit('sentContacts');
  }

  handleRedirectToSettings(type: string) {
    this.redirectToSettings.emit(type);
  }

  // handleFilterMembersCommunity() {
  //   this.filterMembersCommunity.emit();
  // }

  openModalSelectCommunity() {
    const communities = this.nextCommunitys.map(community => community.event);
    const userIsCommunityOwner = communities.some(ev => ev.type === 'COMUNIDADE' && ev.organizer.idUser == this.userProfile?.idUser);
    const dialogRef = this.dialog.open(ModalSelectCommunityComponent, {
      data: {
        communities: this.nextCommunitys.map(community => community.event).filter(ev => ev.id != this.communityItem.id),
        isCommunityOwner: this.userProfilePlan.planName === "Plano Corporativo" && !userIsCommunityOwner,
        showCancelButton: true
      },
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      (result: SluperEvent | string | null) => {
        if (result === null || result === undefined || result === '') return
        if (typeof (result) == 'string') {
          this.router.navigateByUrl('comunidade');
        } else {
          if (!result.eventUrl || result.eventUrl === '' || !result.eventUrl.length) {
            this.toastrService.show('Essa comunidade ainda não cadastrou o seu link de comunidade', 'error');
            return
          }
          this.loadingService.show()

          const myCommunity = result;
          this.openedCommunityService.setCommunity(result.eventUrl!);
          this.openedCommunityService.setMyCommunity(result);

          setTimeout(() => {
            this.router.navigateByUrl('login/' + myCommunity?.eventUrl);
            this.loadingService.hide()
          }, 1000)
        }
      },
      error => {
        this.loadingService.hide()
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

}
