import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Post } from '../../post/posts.service';

@Component({
  selector: 'app-people-liked-post-modal',
  templateUrl: './people-liked-post-modal.component.html',
  styleUrls: ['./people-liked-post-modal.component.css']
})
export class PeopleLikedPostModalComponent {
  public peopleList = [
    {
      id: 'carlos',
      name: 'João Silva',
      status: 'Desenvolvedor',
      photo: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    {
      id: 'carlos',
      name: 'Maria Santos',
      status: 'Designer',
      photo: 'https://randomuser.me/api/portraits/women/12.jpg'
    },
    {
      id: 'carlos',
      name: 'Ana Pereira',
      status: 'Marketing',
      photo: 'https://randomuser.me/api/portraits/women/24.jpg'
    },
    {
      id: 'carlos',
      name: 'Carlos Souza',
      status: 'Gerente de Projetos',
      photo: 'https://randomuser.me/api/portraits/men/31.jpg'
    },
    {
      id: 'carlos',
      name: 'Carlos Souza',
      status: 'Gerente de Projetos',
      photo: 'https://randomuser.me/api/portraits/men/31.jpg'
    },
    {
      id: 'carlos',
      name: 'Carlos Souza',
      status: 'Gerente de Projetos',
      photo: 'https://randomuser.me/api/portraits/men/31.jpg'
    },
    {
      id: 'carlos',
      name: 'Carlos Souza',
      status: 'Gerente de Projetos',
      photo: 'https://randomuser.me/api/portraits/men/31.jpg'
    },
    {
      id: 'carlos',
      name: 'Carlos Souza',
      status: 'Gerente de Projetos',
      photo: 'https://randomuser.me/api/portraits/men/31.jpg'
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<PeopleLikedPostModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { post: Post }
  ) { }


  closeModal() {
    this.dialogRef.close({ success: true });
  }

  async openParticipantProfile(id: string) {
    window.open(`https://sluper.bio/${id}`);
  }


}
