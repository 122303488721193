<div class="container-fluid" id="community-settings">
    <div class="page-title">
        <i class="bi bi-chevron-left"></i>
        <h4 id="mention-title">Configuração da Comunidade </h4>
    </div>


    <section class="tab-selector">
        <div id="event-community-tab-selector">
            <div [class]="selectedTab === 'community-information' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-information')">
                <h2 class="tab-title">Informações da Comunidade</h2>
            </div>
            <div [class]="selectedTab === 'community-members' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-members')">
                <h2 class="tab-title">Membros</h2>
            </div>
            <div [class]="selectedTab === 'community-channels-categories' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-channels-categories')">
                <h2 class="tab-title">Canais e Categorias</h2>
            </div>
            <div [class]="selectedTab === 'community-posts' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-posts')">
                <h2 class="tab-title">Publicações</h2>
            </div>
        </div>
    </section>


    <app-information-settings *ngIf="selectedTab === 'community-information'"></app-information-settings>
    <app-members-settings *ngIf="selectedTab === 'community-members'"></app-members-settings>
    <app-channels-categories-settings
        *ngIf="selectedTab === 'community-channels-categories'"></app-channels-categories-settings>
    <app-posts-settings *ngIf="selectedTab === 'community-posts'"></app-posts-settings>

</div>