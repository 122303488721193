<div class="container-fluid" id="community-mentions">

    <div class="page-title">
        <i class="bi bi-chevron-left"></i>
        <h4 id="mention-title">Favoritos</h4>
    </div>

    <div class="mentioned-posts">
        <section class="post-left">
            <div class="post-month">
                <span>Junho</span>
                <div class="line"></div>
            </div>
            <div class="card-item">
                <app-post-component *ngFor="let post of posts; let i = index" [post]="post"
                    [fromScreen]="'community-favorites'" [userProfile]="userProfile" [communityItem]="communityItem"
                    [selectedChannel]="selectedChannel" [userProfilePlan]="userProfilePlan"
                    [userConnections]="userConnections" [participants]="participants"
                    [isUserCommunityOwner]="isUserCommunityOwner" [attr.id]="'post-' + i">
                    ></app-post-component>
            </div>
        </section>

        <section class="post-right">
            <div class="post-month">
                <span>Filtrar por</span>
            </div>

            <div class="personalized-card mb-3">
                <h4 id="mention-title" class="mt-0 mb-2">Data de Menção</h4>
                <div class="line mb-3"></div>
                <ngx-daterangepicker-material [locale]="calendarLocale" [showCancel]="true"
                    (choosedDate)="onDateChosen($event)">
                </ngx-daterangepicker-material>


                <!-- <input type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"
                    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="false"
                    [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale"
                    [placeholder]="calendarPlaceholder" [keepCalendarOpeningWithRange]="true" class="form-control" /> -->
            </div>

            <div class="personalized-card">
                <h4 id="mention-title" class="mt-0 mb-2">Categorias</h4>
                <div class="line mb-3"></div>
                <div class="card-item-categories">
                    <div class="tag">Categoria 1</div>
                    <div class="tag">Categoria 2</div>
                    <div class="tag">Categoria 3</div>
                    <div class="tag">Categoria 4</div>
                    <div class="tag">Categoria 5</div>
                    <div class="tag">Categoria 6</div>
                </div>
            </div>
        </section>
    </div>
</div>