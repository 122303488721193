import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommunityEvent, CommunityParticipant, ICommunityWithParticipationStatus } from '../model/community.model';
import { Connection } from '../model/connection.model';
import { IDashboardEvent, IDashboardUser } from '../model/dashboard.model';
import { EventParticipant, EventReview, EventWithParticipantsQuantity, IEventWithParticipationStatus, SluperEvent } from '../model/event.model';
import { IImageResponse } from '../model/image-response.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { DocumentUserCSS } from './../model/document-user-css.model';

type CreateEventPayload = Omit<SluperEvent, 'organizer' | 'id'> & {
  organizer: {
    idUser: string;
    name: string;
    bio: string;
    uriImageProfile: string;
    uriImageBackground: string;
    participationStatus?: string
    header: {
      text: string;
      visible: boolean;
    };
  };
};

export type CreateCommunityPayload = Omit<CommunityEvent, 'organizer' | 'id'> & {
  organizer: {
    idUser: string;
    name: string;
    bio: string;
    uriImageProfile: string;
    uriImageBackground: string;
    header: {
      text: string;
      visible: boolean;
    };
  };
};

type SubmitReviewPayload = {
  eventId: string,
  userId: string,
  review: number,
  comment: string
};


interface IFutureUserEvents {
  allEvents: SluperEvent[];
  eventsParticipanting: IEventWithParticipationStatus[];
  eventsOrganizing: SluperEvent[];
}

interface IFutureUserEvents {
  communitysParticipanting: ICommunityWithParticipationStatus[];
  communitysOrganizing: CommunityEvent[];
}

export interface IEventById {
  event: SluperEvent;
  participants: EventParticipant[];
}

interface ICommunityById {
  community: CommunityEvent;
  participants: CommunityParticipant[];
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  baseUrl = environment.eventsApiUrl;
  baseAdmUrl = environment.administradorUrl;
  baseProfileUrl = environment.profileUrl;

  constructor(
    protected http: HttpClient,
    protected jwtHelper: JwtHelperService,
    protected router: Router,
  ) { }

  getEvents(): Observable<HttpResponse<SluperEvent[]>> {
    return this.http.get<SluperEvent[]>(
      `${this.baseUrl}/publicEvents`,
      { observe: 'response' },
    );
  }

  eventById(eventId: string): Observable<HttpResponse<IEventById>> {
    return this.http.get<IEventById>(
      `${this.baseUrl}/eventsById/${eventId}`,
      { observe: 'response' },
    );
  }
  eventByCompanyId(companyId: string): Observable<HttpResponse<SluperEvent[]>> {
    return this.http.get<SluperEvent[]>(
      `${this.baseUrl}/eventsByParentEvent/${companyId}`,
      { observe: 'response' },
    );
  }

  getEventParticipants(eventId: string): Observable<HttpResponse<EventParticipant[]>> {
    return this.http.get<EventParticipant[]>(
      `${this.baseUrl}/participantsByEventId/${eventId}`,
      { observe: 'response' },
    );
  }

  getFutureEvents(userId: string): Observable<HttpResponse<IFutureUserEvents>> {
    return this.http.get<IFutureUserEvents>(
      `${this.baseUrl}/eventsByUser/${userId}`,
      { observe: 'response' },
    );
  }

  getUserConnections(userId: string): Observable<HttpResponse<Connection[]>> {
    return this.http.get<Connection[]>(
      `${this.baseUrl}/connectionsByUser/${userId}`,
      { observe: 'response' },
    );
  }

  getUserPublic(): Observable<HttpResponse<Connection[]>> {
    return this.http.get<Connection[]>(
      `${this.baseUrl}/publicSearch`,
      { observe: 'response' },
    );
  }

  getAddressByCEP(cep: string): Observable<HttpResponse<any>> {
    return this.http.get(
      `https://viacep.com.br/ws/${cep}/json/`,
      { observe: 'response' },
    );
  }

  joinEvent(eventId: string, userId: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/participate`,
      {
        idUser: userId,
        idEvent: eventId,
      },
      {
        observe: 'response',
      }
    );
  }


  validaComunidadeDisponivel(link: string): Observable<boolean | null> {
    return this.http
      .get(`${this.baseUrl}/eventsByUrl/${link}`, { observe: 'response' })
      .pipe(
        map((response: any) => {
          // Caso a resposta tenha um body vazio
          if (!response.body && !response.body?.id) {
            return null;
          }
          // Verifica se há sucesso na resposta
          return true;
        })
      );
  }

  communityByCommunityUrl(url: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/eventsByUrl/${url}`,
      { observe: 'response' }
    );
  }


  connectUser({ participantId, eventId, userId }: { participantId: string; eventId: string | null, userId: string }): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/connect`,
      {
        idUser: userId,
        idEvent: eventId,
        idParticipant: participantId,
      },
      {
        observe: 'response',
      }
    );
  }

  createEvent(event: CreateEventPayload): Observable<HttpResponse<any>> {
    return this.http.post<DocumentUserCSS>(this.baseUrl + '/event', event, {
      observe: 'response',
    });
  }

  createCommunity(event: CreateCommunityPayload): Observable<HttpResponse<any>> {
    return this.http.post<DocumentUserCSS>(this.baseUrl + '/event', event, {
      observe: 'response',
    });
  }
  userById(): Observable<HttpResponse<IUserProfileWithCSS> | null> {
    const token: string | null = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return this.http.get<IUserProfileWithCSS>(
        `${this.baseProfileUrl}/userById/${decodedToken.idUser}`,
        { observe: 'response' },
      );
    } else {
      return of(null);
    }
  }

  approveParticipant({ idEvent, idParticipant, participationApproved }: { idEvent: string, idParticipant: string, participationApproved: boolean }): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${this.baseUrl}/approveParticipant`,
      {
        idEvent: idEvent,
        idParticipant: idParticipant,
        participationApproved: participationApproved,
      },
      {
        observe: 'response',
      }
    );
  }

  deleteEvent(idEvent: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseUrl}/event/${idEvent}`, { observe: 'response' });
  }

  updateEvent(event: CreateEventPayload): Observable<HttpResponse<any>> {
    return this.http.put<DocumentUserCSS>(this.baseUrl + '/event', event, {
      observe: 'response',
    });
  }

  updateCommunity(event: CreateCommunityPayload): Observable<HttpResponse<any>> {
    return this.http.put<DocumentUserCSS>(this.baseUrl + '/event', event, {
      observe: 'response',
    });
  }

  saveEventImage(idEvent: string, imageType: string, img: File): Observable<string> {
    const formData = new FormData();
    formData.append('img', img);

    return this.http
      .put<string>(
        `${this.baseUrl}/imageEvent?idEvent=${idEvent}&imageType=${imageType}`,
        formData,
        { responseType: 'text' as 'json' },
      )
      .pipe(map((response) => response.trim()));
  }
  saveCommunityImageLogo(idEvent: string, photo: File): Observable<{ communityLogo: string }> {
    const formData = new FormData();
    formData.append('photo', photo);

    return this.http.post<{ communityLogo: string }>(
      `${this.baseUrl}/communityLogo/${idEvent}`,
      formData
    ).pipe(
      catchError((error) => {
        console.error('Erro no saveCommunityImageLogo:', error);
        throw error;
      })
    );
  }


  changeCommunityImageLogoShow(idEvent: string,): Observable<string> {
    return this.http
      .put<string>(
        `${this.baseUrl}/communityLogo/${idEvent}`,
        'null',
        { responseType: 'text' as 'json' },
      )
      .pipe(map((response) => response.trim()));
  }

  getImageEvent(idEvent: string): Observable<HttpResponse<IImageResponse>> {
    return this.http.get<IImageResponse>(this.baseUrl + "/imageEvent/" + idEvent, { observe: 'response' });
  }

  sendIndication(idIndicator: string, idIndicated: string, listIdRecipient: string[]): Observable<unknown> {
    return this.http
      .put<string>(
        `${this.baseUrl}/sendIndication`,
        {
          idIndicator,
          idIndicated,
          listIdRecipient
        },
        { responseType: 'text' as 'json' },
      )
      .pipe(map((response) => response.trim()));
  }

  getEventDashboadUser(): Observable<HttpResponse<IDashboardUser>> {
    return this.http.get<IDashboardUser>(
      `${this.baseUrl}/dashboadUser`,
      { observe: 'response' },
    );
  }

  getEventDashboad(eventId: string): Observable<HttpResponse<IDashboardEvent>> {
    return this.http.get<IDashboardEvent>(
      `${this.baseUrl}/dashboadEvent/${eventId}`,
      { observe: 'response' },
    );
  }

  base64ToBlob(base64String: string, contentType = ''): Blob {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  checkInParticipant(participantId: string, eventId: string, checkIn: boolean): Observable<HttpResponse<any>> {
    return this.http.put(this.baseUrl + '/checkinParticipant',
      {
        participantId: participantId,
        eventId: eventId,
        checkIn: checkIn
      },
      {
        observe: 'response'
      }
    )
  }

  removeConnection(participantId: string | any, idConnection: string): Observable<HttpResponse<any>> {
    return this.http.put(this.baseUrl + '/removeConnection',
      {
        participantId: participantId,
        connectionId: idConnection
      },
      {
        observe: 'response'
      }
    )
  }

  submitReview(payload: SubmitReviewPayload): Observable<HttpResponse<any>> {
    return this.http.post(this.baseUrl + '/submitReview', payload, { observe: 'response' });
  }

  getEventReviews(eventId: string): Observable<HttpResponse<EventReview[]>> {
    return this.http.get<EventReview[]>(
      `${this.baseUrl}/eventReviews/${eventId}`,
      { observe: 'response' },
    );
  }

  getCommunitiesByUser(userId: string): Observable<HttpResponse<EventWithParticipantsQuantity[]>> {
    return this.http.get<EventWithParticipantsQuantity[]>(
      `${this.baseUrl}/communitiesByUser/${userId}`,
      { observe: 'response' },
    );
  }

  requestConnection({ participantId, eventId, userId }: { participantId: string; eventId: string | null, userId: string }): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/requestConnection`,
      {
        idUser: userId,
        idEvent: eventId,
        idParticipant: participantId,
      },
      {
        observe: 'response',
      }
    );
  }

  inviteToEventByCSV(eventId: string, file: File): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(`${this.baseUrl}/event/${eventId}/share`, formData, {
      observe: 'response',
    });
  }


  cancelConnectionRequest(senderId: string, receiverId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseUrl}/cancelConnectionRequest/${senderId}/${receiverId}`, { observe: 'response' });
  }

  getSentConnectionRequests(userId: string): Observable<HttpResponse<Connection[]>> {
    return this.http.get<Connection[]>(
      `${this.baseUrl}/sentConnectionRequests/${userId}`,
      { observe: 'response' },
    );
  }

  getPendingConnectionRequests(userId: string): Observable<HttpResponse<Connection[]>> {
    return this.http.get<Connection[]>(
      `${this.baseUrl}/pendingConnectionRequests/${userId}`,
      { observe: 'response' },
    );
  }

  rejectConnectionRequest(senderId: string, receiverId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.baseUrl}/rejectConnectionRequest/${senderId}/${receiverId}`, { observe: 'response' });
  }

  acceptConnectionRequest(senderId: string, receiverId: string): Observable<HttpResponse<any>> {
    return this.http.post(`${this.baseUrl}/acceptConnectionRequest`,
      {
        idUser: senderId,
        idEvent: null,
        idParticipant: receiverId,
      },
      {
        observe: 'response',
      }
    );
  }
  //
}
