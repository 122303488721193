import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, ORDERED_LIST_BUTTON, SEPARATOR, UNDO_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';
import { firstValueFrom } from 'rxjs';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { CreatePostPayload, PostsService } from '../posts.service';

@Component({
  selector: 'app-modal-post-input',
  templateUrl: './modal-post-input.component.html',
  styleUrls: ['./modal-post-input.component.css']
})
export class ModalPostInputComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  @Input() userProfile!: IDocumentUserProfile | null;
  @Input() communityItem!: SluperEvent | null;
  @Input() selectedChannel!: ChannelInterface | null;
  @Input() inputType: 'post' | 'comment' = 'post'
  @Input() postId: number = 0
  @Input() commentId: number | null = null
  public loading = false

  config: EditorConfig = {
    placeholder: this.inputType === 'post' ? 'Sobre o que você quer falar? ...' : 'Digite uma resposta ...',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR,
      FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON],
  };

  inputsData: { title: string, description: string, image?: string, imageFile?: File, movie?: string, file?: string, fileFile?: File } = {
    title: '',
    description: ''
  }

  commentData: { eventId: number, text: string, userId: string, replyTo: string | null } = {
    eventId: 0,
    text: '',
    userId: '',
    replyTo: null
  }
  changeImageEvent: File | null = null

  public title = new FormControl('', Validators.required)
  public description = new FormControl('', Validators.required)
  public showInputMovie = false
  constructor(
    private activeModal: NgbActiveModal,
    private postsService: PostsService,
    private toastrService: ToastService

  ) { }

  ngOnInit(): void {
    this.config.placeholder = this.inputType === 'post' ? 'Sobre o que você quer falar? ...' : 'Digite uma resposta ...';
  }

  async saveCommunityPost() {

    if (this.verifyForm()) {
      this.inputsData.title = this.title.value!;
      this.inputsData.description = this.description.value!;
    } else {
      return
    }

    this.loading = true;
    const result: {
      title?: string;
      description: string;
      image?: string;
      imageFile?: File;
      movie?: string;
      file?: string;
      fileFile?: File;
    } = this.inputsData

    this.loading = true;

    try {
      // Criação do payload do novo post
      const newPost: CreatePostPayload = {
        request: {
          title: result.title!.length > 0 ? result.title : '',
          content: result.description,
          idCommunity: this.communityItem!.id,
          userId: this.userProfile!.idUser,
          channel: this.selectedChannel!.id,
          movie: result.movie || null,
          likes: 0,
        },
        photo: result.imageFile || null,
        file: result.fileFile || null,
      };

      const data = await firstValueFrom(this.postsService.createPost(newPost));

      if (data) {
        this.toastrService.show('Post criado com sucesso.', 'success');
      } else {
        throw new Error('Ocorreu um problema ao salvar o post.');
      }

      setTimeout(() => {
        this.activeModal.close(
          {
            result: true,
            post: data,
            comment: null
          }
        );
        this.loading = false;
      }, 2000);
      // this.fetchPostsData();
    } catch (error) {
      this.toastrService.show('Ocorreu um problema ao salvar o post.', 'error');
      console.error('Erro ao salvar o post:', error);
      this.loading = false
    }
  }

  async createCommentPost() {

    if (!this.verifyForm()) {
      this.toastrService.show('Preencha os campos obrigatórios.', 'warning');
      return
    }
    this.loading = true;

    try {
      this.commentData = {
        eventId: this.postId,
        text: this.description.value!,
        userId: this.userProfile!.idUser,
        replyTo: this.commentId?.toString() || null
      }

      const data = await firstValueFrom(this.postsService.createComment(this.commentData));

      if (data) {
        this.toastrService.show('Comentário criado com sucesso.', 'success');
      } else {
        throw new Error('Ocorreu um problema ao salvar o comentário.');
      }


      setTimeout(() => {
        this.activeModal.close(
          {
            result: true,
            post: null,
            comment: data.body
          }
        );
        this.loading = false;
      }, 2000);
    } catch (error) {
      this.toastrService.show('Ocorreu um problema ao salvar o comentário.', 'error');
      console.error('Erro ao salvar o comentário:', error);
      this.loading = false;
    }
  }

  verifyForm(): boolean {
    this.description.markAllAsTouched();

    if (this.description.valid) {
      return true;
    } else {
      return false;
    }
  }

  onEditorChange(value: string) {
    this.description.setValue(value);
  }

  closeModal() {
    this.activeModal.close(
      {
        result: false,
        post: null,
        comment: null
      }
    );
  }

  handleImageChange(event: any) {
    const file = event.target.files[0];

    // Limpa o evento anterior para forçar a atualização
    this.changeImageEvent = null;

    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validImageTypes.includes(file.type)) {
        this.toastrService.show('Por favor, selecione um arquivo de imagem válido.', 'warning');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          this.deleteItem();
          this.showInputMovie = false;
          this.inputsData.imageFile = file;
          this.inputsData.image = e.target.result;

          // Atribui o novo evento ao cropper
          this.changeImageEvent = event;
        };
      };

      reader.readAsDataURL(file);
    }
  }


  openPdfInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {

        const maxSize = 1 * 1024 * 1024;
        if (file.size > maxSize) {
          this.toastrService.show('O tamanho máximo do arquivo é 1 MB.', 'warning');
          return;
        }

        this.deleteItem();
        this.showInputMovie = false;
        this.inputsData.fileFile = file;

        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.inputsData.file = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  handleVideoInput() {
    this.showInputMovie = !this.showInputMovie

    if (!this.showInputMovie) {
      this.inputsData.movie = ''
    } else {
      this.deleteItem()
    }
  }

  deleteItem() {
    this.inputsData.image = ''
    this.inputsData.imageFile = undefined
    this.inputsData.movie = ''
    this.inputsData.file = ''
    this.inputsData.fileFile = undefined
    this.changeImageEvent = null

    // switch (type) {
    //   case 'image':
    //     this.inputsData.image = ''
    //     break;
    //   case 'movie':
    //     this.inputsData.movie = ''
    //     break;
    //   case 'file':
    //     this.inputsData.file = ''
    //     break;
    // }
  }


  templateImageCropped(event: any): void {
    const blob = event.blob;

    if (blob && blob.size) {
      // Converte o blob em um File
      const croppedFile = new File([blob], this.inputsData.imageFile?.name || 'cropped_image.png', {
        type: blob.type,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;
        if (typeof e.target.result === 'string') {
          this.showInputMovie = false;
          this.inputsData.imageFile = croppedFile;
          this.inputsData.image = e.target.result;
        }
      };

      reader.readAsDataURL(croppedFile);
    }
  }

}
