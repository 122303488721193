
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { environment } from 'src/environments/environment';

export type postUser = {
    name: string;
    email: string;
    profilePicture: string;
    liked: boolean
}
export type CreatePostPayload = {
    request: {
        title?: string;
        content: string;
        idCommunity: string;
        movie: string | null;
        userId: string;
        channel: string;
        likes: number;
    }
    file: File | null;
    photo: File | null;
};

export type PostResponse = {
    items: Post[];
    totalElements: number;
    totalPages: number;
}

export type Post = {
    title: string;
    content: string;
    id: number;
    idCommunity: string;
    movie: string | null;
    photo: string;
    file: string | null;
    createdAt: string;
    likes: number;
    comments: Comment[];
    channel: number;
    user: postUser;
    userId: string;
    like?: Like
}

export type Comment = {
    id: number;
    eventId: number;
    text: string;
    user: postUser;
    createdAt: string;
    replyTo: number;
    likes: number;
    answers?: Comment[]
    userId: string;
    like?: Like
}

export type Like = {
    id: number;
    comment?: Comment;
    createdAt: string;
    type: string;
    post?: Post;
    user: DocumentUserProfile
}

@Injectable({
    providedIn: 'root',
})
export class PostsService {
    baseUrl = environment.eventsApiUrl;
    baseAdmUrl = environment.administradorUrl;
    baseProfileUrl = environment.profileUrl;

    constructor(
        protected http: HttpClient,
        protected jwtHelper: JwtHelperService,
        protected router: Router,
    ) { }

    getPostsByCommunity(data: { page: number, pageSize: number, eventId: string }): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(
            `${this.baseUrl}/posts/list?page=${data.page}&pageSize=${data.pageSize}&eventId=${data.eventId}`,
            { observe: 'response' },
        );
    }

    getPostsByChannel(data: { page: number, pageSize: number, channelId: string }): Observable<HttpResponse<PostResponse>> {
        return this.http.get<PostResponse>(
            // `${this.baseUrl}/posts/list?page=${data.page}&pageSize=${data.pageSize}&eventId=${data.eventId}`,
            `${this.baseUrl}/posts/list/channel/${data.channelId}?page=${data.page}&pageSize=${data.pageSize}`,
            { observe: 'response' },
        );
    }

    sharePost(data: { senderId: string, receiverId: string, eventPostId: number }) {
        return this.http.post<any>(`${this.baseUrl}/posts/share`, data, {
            observe: 'response',
        });
    }

    createPost(data: CreatePostPayload): Observable<HttpResponse<any>> {
        const formData = new FormData();

        // Criação do payload JSON como string
        const requestPayload = JSON.stringify({
            title: data.request.title || '',
            content: data.request.content,
            idCommunity: data.request.idCommunity,
            movie: data.request.movie || null,
            userId: data.request.userId,
            likes: data.request.likes,
            channel: data.request.channel,
        });

        // Adicionando o JSON ao FormData com Content-Type explícito
        const blob = new Blob([requestPayload], { type: 'application/json' });
        formData.append('request', blob);

        if (data.photo) {
            formData.append('photo', data.photo, data.photo.name);
        }

        if (data.file) {
            formData.append('file', data.file, data.file.name);
        }

        return this.http.post<any>(`${this.baseUrl}/posts`, formData, {
            observe: 'response',
        });
    }

    deletePost(idPost: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.baseUrl}/posts/${idPost}`, {
            observe: 'response',
        });
    }

    createComment(data: { eventId: number, text: string, userId: string, replyTo: string | null }) {
        return this.http.post<any>(`${this.baseUrl}/posts/comments`, data, {
            observe: 'response',
        });
    }

    deleteComment(idComment: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.baseUrl}/posts/comments/${idComment}`, {
            observe: 'response',
        });
    }

    getUserLikes(idUser: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(
            `${this.baseUrl}/posts/comments/reaction/user/${idUser}`,
            { observe: 'response' },
        );
    }

    createPostLike(data: { idPost: number | null, idUser: string, type: string }) {
        // type === LIKE ou REJECT
        return this.http.post<any>(`${this.baseUrl}/posts/reaction/`, data, {
            observe: 'response',
        });
    }
    createCommentLike(data: { idPost: number | null, idComment: number | null, idUser: string, type: string }) {
        // type === LIKE ou REJECT
        return this.http.post<any>(`${this.baseUrl}/posts/comments/reaction/`, data, {
            observe: 'response',
        });
    }

    deleteLike(idLike: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.baseUrl}/posts/comments/reaction/${idLike}`, {
            observe: 'response',
        });
    }


}