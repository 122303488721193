import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { Comment, PostsService } from '../posts.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentComponent implements OnInit {
  @Input() comment!: Comment
  @Input() isUserCommunityOwner: boolean = false
  @Input() userProfile: DocumentUserProfile | undefined
  @Input() communityItem!: SluperEvent
  @Input() selectedChannel!: ChannelInterface | null
  @Input() showDeleteButton: boolean = true
  @Output() answerComment = new EventEmitter<number>();
  @Output() deleteComment = new EventEmitter<number>();
  @Output() likeComment = new EventEmitter<number>();
  @Output() unlikeComment = new EventEmitter<number>();

  public sanitizedComment: SafeHtml = '';


  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private postsService: PostsService
  ) { }

  ngOnInit(): void {
    this.sanitizedComment = this.sanitizer.bypassSecurityTrustHtml(
      this.comment.text || ''
    );
  }
  handleDeleteComment(id: number) {
    this.deleteComment.emit(id)
  }

  transformDate(date: string | Date): string {
    const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const data = new Date(date);
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    return `${mes} ${dia} às ${hora}:${minutos}`;
  }

  handleAnswerComment(id: number) {
    this.answerComment.emit(id)
  }

  likePost(commentId: number) {
    this.likeComment.emit(commentId)
  }
  unlikePost(commentId: number) {
    this.unlikeComment.emit(commentId)
  }

  async openParticipantProfile(event: Event, id: string) {
    event.stopPropagation();
    window.open(`https://sluper.bio/${id}`);
  }
}
