<div id="modal-people-liked-post">
    <div class="close">
        <span class="close-icon" (click)="closeModal()">
            <i class="bi bi-x"></i>
        </span>
    </div>
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="modal-header">
            <span class="like-icon">
                <i class="bi bi-hand-thumbs-up"></i>
            </span>
            <span class="like-count">33 curtidas</span>
        </div>

        <h2 class="modal-subtitle">Membros que curtiram</h2>

        <div class="user-list">
            <div class="user-item" *ngFor="let person of peopleList">
                <img [src]="person.photo" [alt]="person.name" class="user-avatar" />
                <div class="user-info">
                    <span class="user-name">{{ person.name }}</span>
                    <span class="user-status">{{ person.status }}</span>
                    <a (click)="openParticipantProfile(person.id)" class="user-link">Ver perfil</a>
                </div>
            </div>
        </div>
    </div>
</div>