import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { OpenedCommunityService } from '../opened-community.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    protected authService: AuthService,
    private openedCommunityService: OpenedCommunityService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Verifique se a URL da solicitação é para um endpoint que não requer autenticação
    let isUnauthenticatedEndpoint =
      request.url.includes('/api/bizConnect/security/login') ||
      request.url.includes('/api/bizConnect/security/emailKey') ||
      request.url.includes('/api/bizConnect/security/validateToken') ||
      request.url.includes('/api/bizConnect/admin/license') ||
      request.url.includes('/api/bizConnect/admin/verificationKey') ||
      request.url.includes('/api/bizConnect/admin/firstAccess') ||
      request.url.includes('/api/bizConnect/events/eventsById') ||
      request.url.includes('/api/bizConnect/events/participantsByEventId');

    if (request.url.includes('/api/bizConnect/admin/licenses')) {
      isUnauthenticatedEndpoint = false;
    }

    if (isUnauthenticatedEndpoint) {
      return next.handle(request);
    } else {
      // Caso contrário, adicione o cabeçalho "Authorization"
      const token = localStorage.getItem('authToken');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          // Tratar o erro 401 (não autorizado)
          if (error.status === 401) {
            // Notifique os componentes antes de realizar o logout
            this.authService.notifyUnauthorized();

            // Aguarde 2 segundos antes de realizar o logout e redirecionar
            setTimeout(() => {
              this.authService.logout();

              // Verifica se há uma comunidade ativa
              const communityId = this.openedCommunityService.getCommunity();
              if (communityId) {
                this.router.navigate(['/login', communityId]); // Redireciona para login/comunidadeID
              } else {
                this.router.navigate(['/login']); // Redireciona para login
              }
            }, 2000);
          }

          // Rejeitar o erro para ser tratado no componente, caso necessário
          return throwError(error);
        })
      );
    }
  }
}
