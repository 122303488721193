import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-members-settings',
  templateUrl: './members-settings.component.html',
  styleUrls: ['./members-settings.component.css']
})
export class MembersSettingsComponent implements OnInit {
  selectedTab: 'active' | 'inactive' = 'active';

  // Lista fictícia de membros ativos (usando imagens de https://randomuser.me)
  activeMembers = [
    {
      nome: 'Ana Silva',
      statusCargo: 'Designer',
      imageUrl: 'https://randomuser.me/api/portraits/women/10.jpg'
    },
    {
      nome: 'Carlos Oliveira',
      statusCargo: 'Desenvolvedor',
      imageUrl: 'https://randomuser.me/api/portraits/men/20.jpg'
    },
    {
      nome: 'Beatriz Santos',
      statusCargo: 'Gerente de Projetos',
      imageUrl: 'https://randomuser.me/api/portraits/women/25.jpg'
    },
    {
      nome: 'Gabriel Souza',
      statusCargo: 'QA',
      imageUrl: 'https://randomuser.me/api/portraits/men/30.jpg'
    },
    {
      nome: 'Mariana Carvalho',
      statusCargo: 'Analista de Marketing',
      imageUrl: 'https://randomuser.me/api/portraits/women/40.jpg'
    },
    {
      nome: 'Pedro Lima',
      statusCargo: 'Scrum Master',
      imageUrl: 'https://randomuser.me/api/portraits/men/45.jpg'
    },
    {
      nome: 'Roberta Reis',
      statusCargo: 'Product Owner',
      imageUrl: 'https://randomuser.me/api/portraits/women/50.jpg'
    },
    {
      nome: 'Tiago Santos',
      statusCargo: 'Desenvolvedor',
      imageUrl: 'https://randomuser.me/api/portraits/men/55.jpg'
    }
  ];

  // Lista fictícia de categorias de membros
  categories = [
    {
      nome: 'Comuns',
      data: '10/00/00',
      qtd: 10
    },
    {
      nome: 'Vips',
      data: '10/00/00',
      qtd: 10
    }
  ];

  // Lista fictícia de membros inativos
  inactiveMembers = [
    {
      nome: 'Fabiana Correia',
      statusCargo: 'Ex-colaboradora',
      imageUrl: 'https://randomuser.me/api/portraits/women/61.jpg'
    },
    {
      nome: 'João Mendes',
      statusCargo: 'Ex-colaborador',
      imageUrl: 'https://randomuser.me/api/portraits/men/62.jpg'
    }
  ];

  constructor() {
    // Construtor vazio
  }

  ngOnInit(): void {
    // Lógica de inicialização, se necessário
  }

  // Exemplo de método para remover um membro
  removeMember(member: any): void {
    // Aqui você pode criar uma lógica para remover o "member" da lista activeMembers
    console.log('Removendo membro:', member);
  }

  // Exemplo de método para adicionar um membro
  addMember(member: any): void {
    // Aqui você pode criar uma lógica para adicionar um novo "member" na lista activeMembers
    console.log('Adicionando membro:', member);
  }
}
