<div class="members-settings-container">

    <!-- Seção: Membros (com abas) -->
    <div class="members-wrapper">

        <!-- ABA NO ESTILO DO ITEM PADRÃO -->
        <section class="tab-selector">
            <div id="event-community-tab-selector">
                <div [class]="selectedTab === 'active' ? 'tab selected-tab' : 'tab'" (click)="selectedTab = 'active'">
                    <h2 class="tab-title">Membros Ativos</h2>
                </div>
                <div [class]="selectedTab === 'inactive' ? 'tab selected-tab' : 'tab'"
                    (click)="selectedTab = 'inactive'">
                    <h2 class="tab-title">Membros Inativos</h2>
                </div>
            </div>
        </section>
        <!-- FIM DA ABA NO ESTILO DO ITEM PADRÃO -->

        <!-- Conteúdo da aba Membros Ativos -->
        <div class="tab-content" *ngIf="selectedTab === 'active'">
            <div class="members-header">
                <h2>Membros da comunidade</h2>
                <div class="members-actions">
                    <input type="text" placeholder="Procurar membros" />
                    <button class="add-members-btn">
                        <i class="bi bi-plus"></i>
                        Adicionar Membros
                    </button>
                </div>
            </div>

            <div class="people-container">
                <div class="connection-request-card" *ngFor="let member of activeMembers">
                    <img *ngIf="member.imageUrl && member.imageUrl !== ''" [src]="member.imageUrl"
                        alt="Foto do usuário" />
                    <img *ngIf="!member.imageUrl || !member.imageUrl.length" src="/assets/img/imagem_perfil.png"
                        alt="Foto padrão" />
                    <div class="request-info">
                        <div class="info">
                            <span class="request-name">{{ member.nome }}</span>
                            <span class="request-profession multi-line">{{ member.statusCargo }}</span>
                        </div>
                    </div>
                    <i class="btn bi bi-dash-square-fill quantity-people-button" (click)="removeMember(member)"></i>
                </div>
            </div>
        </div>

        <!-- Conteúdo da aba Membros Inativos -->
        <div class="tab-content" *ngIf="selectedTab === 'inactive'">
            <div class="members-header">
                <h2>Membros da comunidade inativos</h2>
                <div class="members-actions">
                    <input type="text" placeholder="Procurar membros" />
                    <button class="add-members-btn">
                        <i class="bi bi-plus"></i>
                        Reativar Membros
                    </button>
                </div>
            </div>

            <div class="people-container">
                <div class="connection-request-card" *ngFor="let member of inactiveMembers">
                    <img *ngIf="member.imageUrl && member.imageUrl !== ''" [src]="member.imageUrl"
                        alt="Foto do usuário" />
                    <img *ngIf="!member.imageUrl || !member.imageUrl.length" src="/assets/img/imagem_perfil.png"
                        alt="Foto padrão" />
                    <div class="request-info">
                        <div class="info">
                            <span class="request-name">{{ member.nome }}</span>
                            <span class="request-profession multi-line">{{ member.statusCargo }}</span>
                        </div>
                    </div>
                    <i class="btn bi bi-dash-square-fill quantity-people-button" (click)="removeMember(member)"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- Seção: Categorias de Membros (fora das abas) -->
    <div class="category-container">
        <div class="category-header">
            <h3>Categoria de membros</h3>
            <button class="add-members-btn">
                <i class="bi bi-plus"></i>
                Criar Categoria de Membros
            </button>
        </div>

        <!-- ENVOLVENDO A TABELA EM UMA DIV PARA SCROLL HORIZONTAL -->
        <div class="table-wrapper">
            <table class="member-categories-table">
                <thead>
                    <tr>
                        <th>Nome da categoria</th>
                        <th>Data</th>
                        <th>Qtd. Membros</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cat of categories">
                        <td>{{ cat.nome }}</td>
                        <td>{{ cat.data }}</td>
                        <td>{{ cat.qtd }}</td>
                        <td>
                            <i class="bi bi-trash mx-1"></i>
                            <i class="bi bi-pencil-square mx-1"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>