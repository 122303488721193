import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, firstValueFrom, map } from 'rxjs';

import { CheckPreferencesModalComponent } from 'src/app/contacts-and-connections/check-preferences/check-preferences.component';
import { UserPresentationModalComponent } from 'src/app/contacts-and-connections/user-presentation/user-presentation.component';
import { CreateCommunityPayload, EventsService } from 'src/app/events/events.service';
import { CommunityEvent } from 'src/app/model/community.model';
import { Connection } from 'src/app/model/connection.model';
import { IDocumentUserProfile, IDocumentUserProfileWithPreferences } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IHeader } from 'src/app/model/header.model';
import { IImageResponse } from 'src/app/model/image-response.model';
import { INetworkingControl } from 'src/app/model/networking-control.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { IUserProfileWithCSS } from 'src/app/model/user-profile-css.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';

/** Definição interna para simplificar */
interface IConnection {
  connectionDate: string;
  event?: {
    id: string;
    name: string;
  } | null;
  connection: IDocumentUserProfileWithPreferences | EventParticipant;
  indication?: boolean | undefined | null;
  indicatorName?: string | undefined | null;
}

@Component({
  selector: 'app-modal-community-members',
  templateUrl: './modal-community-members.component.html',
  styleUrls: ['./modal-community-members.component.css']
})
export class ModalCommunityMembersComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  /** =============================
   *  Estados e variáveis globais
   *  ============================= */
  public loadingConnections = false;
  public defaultImageProfile = '/assets/img/imagem_perfil.png';
  public defaultBackgroundImage = '/assets/img/imagem_fundo.png';

  // Lista original de participantes e a lista filtrada
  public participants: EventParticipant[] = [];
  public allParticipants: EventParticipant[] = [];

  // Conexões pendentes enviadas pelo usuário
  public sentConnections: Connection[] = [];

  // Tipos possíveis: 'add', 'connect', 'show', 'share', 'delete'
  public type: 'add' | 'connect' | 'show' | 'share' | 'delete' = 'add';
  public communityItem!: SluperEvent;

  // Controle do input CSV
  public csvFile: File | null = null;

  // Formulário de email
  formModel = {
    email: '',
    isValid: false,
    errorMessage: ''
  };

  // Controla se houve alguma mudança que o componente pai precise saber
  refreshData = false;

  // Injetados via data
  public userProfile!: IDocumentUserProfile;
  public userProfilePlan!: IUserProfilePlan;
  public userConnections: Connection[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      participants: EventParticipant[],
      userProfile: IDocumentUserProfile,
      userProfilePlan: IUserProfilePlan,
      userConnections: Connection[],
      communityItem: SluperEvent,
      type: 'add' | 'connect' | 'show' | 'share' | 'delete'
    },
    private modalService: NgbModal,
    private userProfileService: UserProfileService,
    private eventsService: EventsService,
    private matDialogRef: MatDialogRef<ModalCommunityMembersComponent>,
    private toastrService: ToastService
  ) { }

  async ngOnInit(): Promise<void> {
    // Carrega dados injetados
    if (this.data) {
      this.userProfile = this.data.userProfile;
      this.userProfilePlan = this.data.userProfilePlan;
      this.type = this.data.type;
      this.userConnections = this.data.userConnections;
      this.communityItem = this.data.communityItem;

      // Ajusta "participants" e "allParticipants" conforme o tipo
      this.setupParticipantsList();

      // Se for connect, busca as conexões enviadas (para trocar para "Pendente" quando selecionado)
      if (this.type === 'connect') {
        await this.getSentConnections();
      }
    }
  }

  /** ============================================================
   *  Monta a lista de participants conforme o `type`
   *  ============================================================ */
  private setupParticipantsList(): void {
    // `data.participants` = pessoas da comunidade ou que podem ser convidadas
    // `userConnections` = conexões do usuário

    switch (this.type) {
      case 'connect':
        // Para "connect", mostramos a lista de `data.participants` mesmo.
        this.participants = this.data.participants;
        this.allParticipants = this.data.participants;
        break;

      case 'add':
        // "add" - remover quem já está na comunidade
        // 1) Obtem IDs de quem já está na comunidade
        const participantIds = new Set(this.data.participants.map(p => p.idUser));
        // 2) Mapeia userConnections em EventParticipant
        const mapped = this.userConnections.map(conn => ({
          ...conn.connection,
          checkIn: conn.connection.checkIn ?? false
        })) as EventParticipant[];
        // 3) Filtra quem não está na comunidade
        this.participants = mapped.filter(ep => !participantIds.has(ep.idUser));
        this.allParticipants = this.participants;
        break;

      case 'show':
        // Você pode customizar se quiser uma lógica diferente
        this.participants = this.data.participants;
        this.allParticipants = this.data.participants;
        break;

      case 'share':
        this.participants = this.data.participants;
        this.allParticipants = this.data.participants;
        break;

      case 'delete':
        this.participants = this.data.participants;
        this.allParticipants = this.data.participants;
        break;
    }
  }

  /** ============================================================
   *  Busca conexões que o usuário já enviou (pendentes)
   *  ============================================================ */
  private async getSentConnections() {
    try {
      const response = await this.eventsService
        .getSentConnectionRequests(this.userProfile.idUser)
        .pipe(
          filter((res: HttpResponse<Connection[]>) => res.ok),
          map((res: HttpResponse<Connection[]>) => res.body || [])
        )
        .toPromise();

      this.sentConnections = response || [];
    } catch (error) {
      console.error('Erro ao buscar conexões enviadas:', error);
    }
  }

  /** ============================================================
   *  Helpers para template (connect)
   *  ============================================================ */
  isUserSentConnection(id: string): boolean {
    // Se `id` está em this.sentConnections, é pendente
    return this.sentConnections.some(c => c.connection.idUser === id);
  }

  isParticipantMyConnection(connection: EventParticipant): boolean {
    // Se `idUser` do participant está em userConnections, já é conectado
    return this.userConnections.some(c => c.connection.idUser === connection.idUser);
  }

  /** ============================================================
   *  Funções de "Ver" (interesses, apresentação)
   *  ============================================================ */
  checkConnectionPresentation(event: Event, connection: EventParticipant) {
    event.stopPropagation();
    const modalRef = this.modalService.open(UserPresentationModalComponent, {
      fullscreen: false,
      centered: true
    });
    modalRef.componentInstance.userPresentation = connection.presentation && connection.presentation.length ?
      connection.presentation : 'O usuário ainda nao possui apresentação.';
  }

  checkConnectionInterests(event: Event, connection: EventParticipant) {
    event.stopPropagation();
    const modalRef = this.modalService.open(CheckPreferencesModalComponent, {
      fullscreen: false,
      centered: true
    });
    modalRef.componentInstance.connectionName = connection.name;
    modalRef.componentInstance.preferences = connection.preferences;
  }

  /** ============================================================
   *  Ações: Conectar, Convidar, Compartilhar, Remover...
   *  ============================================================ */
  async handleConnect(
    event: Event,
    userId: string,
    connection: IDocumentUserProfileWithPreferences | EventParticipant,
    index: number
  ) {
    event.stopPropagation();
    if (!this.userProfile.idUser) return;

    this.loadingConnections = true;
    try {
      await firstValueFrom(
        this.eventsService.requestConnection({
          participantId: userId,
          userId: this.userProfile.idUser,
          eventId: this.communityItem.id
        })
      );

      this.toastrService.show('Solicitação de conexão enviada com sucesso!', 'success');

      // Atualiza localmente para virar "pendente"
      const newConnection: Connection = {
        connectionDate: new Date().toISOString(),
        event: {
          id: this.communityItem.id,
          name: this.communityItem.name
        },
        connection: {
          idUser: connection.idUser,
          name: connection.name,
          bio: connection.bio,
          showImageProfile: false,
          publish: false,
          parent: '',
          listURI: [],
          uriImageProfile: connection.uriImageProfile,
          uriImageBackground: connection.uriImageBackground,
          listSocialNetwork: [],
          listButtons: [],
          listUriImages: [],
          header: {} as IHeader,
          listContacts: [],
          networkingControl: {} as INetworkingControl,
          listProfile: []
        }
      };
      this.sentConnections.push(newConnection);

      // Marca que houve alterações
      this.refreshData = true;
    } catch (error: any) {
      console.error('Erro ao tentar conectar:', error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar conectar com este usuário. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loadingConnections = false;
    }
  }

  async invitePersonToCommunityByEmail(event: Event) {
    event.stopPropagation();
    if (!this.validateEmail()) return;

    try {
      // Verifica se já está nos participants (para não repetir)
      if (this.data.participants.some(p => p.email === this.formModel.email)) {
        this.toastrService.show('Este usuário já foi convidado.', 'error');
        this.resetEmailForm();
        return;
      }

      this.loadingConnections = true;

      let event: any = {
        ...this.communityItem,
        guests: [this.formModel.email],
      }
      await firstValueFrom(this.eventsService.updateCommunity(event));

      this.toastrService.show('Usuário convidado com sucesso!', 'success');
      this.refreshData = true;
      this.resetEmailForm();

    } catch (error) {
      console.error(error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar convidar este usuário. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loadingConnections = false;
    }
  }



  private resetEmailForm() {
    this.formModel = { email: '', isValid: false, errorMessage: '' };
  }


  async invitePersonToCommunity(event: Event, person: EventParticipant, index: number) {
    event.stopPropagation();
    this.loadingConnections = true;

    try {
      // Busca o perfil para pegar o e-mail real
      const user = await this.getUserById(person.idUser);
      if (!user) return;

      // Aqui pegamos o e-mail do usuário
      const userEmail = user.documentUser.email;

      let event: any = {
        ...this.communityItem,
        guests: [userEmail],
      }
      await firstValueFrom(this.eventsService.updateCommunity(event));

      this.toastrService.show('Usuário convidado com sucesso!', 'success');

      // Remove local da lista, se desejar
      this.participants.splice(index, 1);

      this.refreshData = true;
    } catch (error) {
      console.error(error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar convidar este usuário. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loadingConnections = false;
    }
  }



  removeParticipant(event: Event, person: EventParticipant, index: number) {
    event.stopPropagation();
    // Exemplo: remover do array local
    // Em casos reais, chamaria algo como `this.eventsService.removeFromCommunity(...)`
    this.participants.splice(index, 1);
    this.refreshData = true;
    this.toastrService.show(`Participante ${person.name} removido com sucesso.`, 'success');
  }

  shareWith(event: Event, idUser: string) {
    event.stopPropagation();
    // Emite para o componente pai saber com quem iremos compartilhar
    // A modal se fecha com a info { success: true, idUser }
    this.matDialogRef.close({ success: true, idUser });
  }

  /** ============================================================
   *  Busca perfil do usuário (para convidar)
   *  ============================================================ */
  async getUserById(id: string): Promise<IUserProfileWithCSS | ''> {
    try {
      const response = await this.userProfileService
        .userById(id)
        .pipe(
          filter((res: HttpResponse<IUserProfileWithCSS>) => res.ok),
          map((res: HttpResponse<IUserProfileWithCSS>) => res.body)
        )
        .toPromise();
      return response || '';
    } catch (error) {
      console.error(`Erro ao buscar usuário com ID ${id}:`, error);
      return '';
    }
  }

  /** ============================================================
   *  Fechar modal
   *  ============================================================ */
  closeModal(force?: boolean) {
    // Fecha a dialog e retorna se houve refreshData
    this.matDialogRef.close(force || this.refreshData);
  }

  /** ============================================================
   *  Métodos de CSV
   *  ============================================================ */
  triggerFileInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file && file.name.endsWith('.csv')) {
        this.processCsvFile(file);
      } else {
        this.toastrService.show('Por favor, selecione um arquivo CSV válido.', 'warning');
      }
    };
    input.click();
  }

  processCsvFile(file: File) {
    this.csvFile = file;
  }

  removeFile() {
    this.csvFile = null;
  }

  async sendInvitesByCsv() {
    if (!this.csvFile) {
      this.toastrService.show('Por favor, selecione um arquivo CSV antes de enviar.', 'warning');
      return;
    }
    this.loadingConnections = true;
    this.toastrService.show('Enviando convites, por favor aguarde...', 'info');

    try {
      await firstValueFrom(this.eventsService.inviteToEventByCSV(this.communityItem.id, this.csvFile));
      this.csvFile = null;
      this.toastrService.show('Convites enviados com sucesso!', 'success');
      this.refreshData = true;
    } catch (error: any) {
      console.error('Erro ao enviar convites:', error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar enviar os convites. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loadingConnections = false;
    }
  }

  /** ============================================================
   *  Filtro para lista de participantes
   *  ============================================================ */
  filterConnections(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value.toLowerCase().trim();

    if (!searchTerm) {
      this.participants = this.allParticipants;
      return;
    }
    this.participants = this.allParticipants.filter(
      connection => connection.name.toLowerCase().includes(searchTerm)
    );
  }

  /** ============================================================
   *  Validação de email
   *  ============================================================ */
  validateEmail(): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (this.formModel.email.trim() === '') {
      this.formModel.isValid = false;
      this.formModel.errorMessage = 'O campo de email não pode estar vazio.';
      return false;
    }

    if (!emailRegex.test(this.formModel.email)) {
      this.formModel.isValid = false;
      this.formModel.errorMessage = 'Por favor, insira um email válido.';
      return false;
    }

    this.formModel.isValid = true;
    this.formModel.errorMessage = '';
    return true;
  }

  /** ============================================================
   *  Outros métodos (gerar vCard, abrir perfil etc.)
   *  ============================================================ */
  openConnectionProfile(id: number | string) {
    window.open(`https://sluper.bio/${id}`);
  }

  getBackground(connection: EventParticipant) {
    return connection.uriImageBackground
      ? connection.uriImageBackground
      : this.defaultBackgroundImage;
  }

  generateVcardConnections(event: Event, profile: EventParticipant) {
    event.stopPropagation();
    if (!profile.idUser) return;

    this.loadingConnections = true;

    try {
      this.userProfileService.userById(profile.idUser)
        .pipe(
          filter((res: HttpResponse<IUserProfileWithCSS>) => res.ok),
          map((res: HttpResponse<IUserProfileWithCSS>) => res.body)
        )
        .subscribe({
          next: (userRes) => {
            this.userProfileService.getImageProfile(profile.idUser)
              .pipe(
                filter((res: HttpResponse<IImageResponse>) => res.ok),
                map((res: HttpResponse<IImageResponse>) => res.body)
              )
              .subscribe({
                next: (imageRes) => {
                  this.montaVCard(userRes?.documentUserProfile!, imageRes?.base64Image);
                  this.loadingConnections = false;
                },
                error: (err) => {
                  console.error('Erro ao buscar imagem de perfil', err);
                  this.montaVCard(userRes?.documentUserProfile!);
                  this.loadingConnections = false;
                }
              });
          },
          error: (err) => {
            console.error('Erro ao buscar perfil do usuário', err);
            this.loadingConnections = false;
          }
        });
    } catch (error) {
      console.error('Erro inesperado', error);
      this.loadingConnections = false;
    }
  }

  private buildCreateCommunityPayloadSingle(
    baseCommunity: CommunityEvent,
    newGuest: string
  ): CreateCommunityPayload {
    return {
      name: baseCommunity.name,
      website: baseCommunity.website,
      type: baseCommunity.type,
      isPublic: baseCommunity.isPublic,
      description: baseCommunity.description,
      imageLink: baseCommunity.imageLink,
      requiresConfirmation: baseCommunity.requiresConfirmation,
      about: baseCommunity.about || '',

      /**
       * guests: sobrescreve completamente a lista,
       * usando apenas o e-mail convidado.
       */
      guests: [newGuest],

      organizer: {
        idUser: baseCommunity.organizer.idUser,
        name: baseCommunity.organizer.name,
        bio: '',
        uriImageProfile: baseCommunity.organizer.uriImageProfile,
        uriImageBackground: '',
        header: {
          text: '',
          visible: true
        }
      }
    };

  }

  montaVCard(profile: IDocumentUserProfile, profileImageBase64?: string) {
    let vcardContent = `BEGIN:VCARD
VERSION:3.0
FN:${profile.name}
URL;CHARSET=UTF-8:https://sluper.bio/${profile.idUser}`;

    if (profileImageBase64) {
      vcardContent += `\nPHOTO;TYPE=JPEG;ENCODING=b:${profileImageBase64}`;
    }

    const contactTypePrefixes = {
      telefone: 'TEL;TYPE=WORK,VOICE:',
      email: 'EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:',
      localizacao: 'ADR;CHARSET=UTF-8;TYPE=HOME:'
    };

    if (profile.listContacts) {
      profile.listContacts.forEach(contact => {
        const prefix = contactTypePrefixes[contact.type as 'telefone' | 'email' | 'localizacao'];
        if (prefix) {
          vcardContent += `\n${prefix}${contact.value}`;
        }
      });
    }

    if (profile.listSocialNetwork) {
      profile.listSocialNetwork.forEach(social => {
        if (social.link) {
          vcardContent += `\nURL;CHARSET=UTF-8:${social.link}`;
        }
      });
    }

    vcardContent += '\nEND:VCARD';

    const blob = new Blob([vcardContent], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'contatoSluper.vcf');
    link.click();
    window.URL.revokeObjectURL(url);
  }
}
