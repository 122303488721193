import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { Comment } from '../posts.service';

@Component({
  selector: 'app-answers-comment',
  templateUrl: './answers-comment.component.html',
  styleUrls: ['./answers-comment.component.css']
})
export class AnswersCommentComponent {
  @Input() answersComment!: Comment
  @Input() isUserCommunityOwner: boolean = false
  @Input() showDeleteButton: boolean = true
  @Input() userProfile: DocumentUserProfile | undefined
  @Output() deleteComment = new EventEmitter<number>();
  @Output() likeComment = new EventEmitter<number>();
  @Output() unlikeComment = new EventEmitter<number>();
  public sanitizedComment: SafeHtml = '';


  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,

  ) { }

  ngOnInit(): void {
    this.sanitizedComment = this.sanitizer.bypassSecurityTrustHtml(
      this.answersComment.text || ''
    );
  }

  handleDeleteComment(id: number) {
    this.deleteComment.emit(id)
  }

  transformDate(date: string | Date): string {
    const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const data = new Date(date);
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    return `${mes} ${dia} às ${hora}:${minutos}`;
  }

  likePost(commentId: number) {
    this.likeComment.emit(commentId)
  }
  unlikePost(commentId: number) {
    this.unlikeComment.emit(commentId)
  }

  async openParticipantProfile(event: Event, id: string) {
    event.stopPropagation();
    window.open(`https://sluper.bio/${id}`);
  }
}
