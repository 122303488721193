<app-sluper-background *ngIf="!customBg" [removeBackground]="customBg ? true : false"></app-sluper-background>
<app-header-inicial></app-header-inicial>
<div *ngIf="loading" class="overlay" [style.background]="'#fff'">
  <div class="spinner"></div>
</div>

<div class="d-flex align-items-start justify-content-center vh-100 container" style="padding-top: 5vh"
  *ngIf="!myCommunity">
  <div class="row w-100 d-flex justify-content-center align-items-center">
    <!-- Coluna para o formulário de login -->
    <div class="col-12 col-md-5 col-sm-12 d-flex flex-column justify-content-center mb-md-0 mb-4">
      <div class="shadow-box">
        <span class="text-normal-32-700 text-white" translate>Acessar</span>
        <div class="form-div">
          <label for="user" class="label-text-inicio" translate>Email</label>
          <input type="email" name="user" id="user" class="form-input col-12 col-sm-12 col-md-10 col-lg-8"
            [formControl]="userEmail" />
        </div>
        <div class="form-div" *ngIf="foiEnviadoCodVerf">
          <label for="verification" class="label-text-inicio" translate>Código de Verificação</label>
          <app-codigo-verificacao></app-codigo-verificacao>
          <span class="text-reenviar-codigo d-inline-block mt-1 text-white" (click)="reenviarCodigo()"
            translate>Reenviar Código</span>
        </div>
        <button type="button" class="btn button-form w-100" (click)="entrar()">
          <span class="text-normal-16-700 text-white" translate>{{ buttonLabelEntrar }}</span>
        </button>
        <label [hidden]="true" (click)="navegarCadastro()" style="color: white">Cadatre-se</label>
      </div>
    </div>

    <!-- Coluna para a imagem e mensagem -->

    <div class="col-12 col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-end">
      <div class="image-box position-relative">
        <div class="image-content-box">
          <div class="text-image-box">
            <span class="text-normal-40-700 text-white">Ainda não tem Sluper?</span>
            <span class="text-line-height text-white">Não deixe de se conectar com o que importa.</span>
          </div>
          <button type="button" class="btn button-roxo-form w-100" (click)="navegarCadastro()">
            <span class="text-normal-16-700 text-white" translate>Cadastre-se</span>
          </button>
        </div>
        <img class="image-cadastro" src="../../../../assets/images/homem-cadastro.png" alt="Anuncio Sluper" />
      </div>
    </div>
  </div>
</div>

<div class="container-community d-flex align-items-start justify-content-center vh-100 container"
  style="padding-top: 5vh" *ngIf="myCommunity">
  <div class="w-75 d-flex justify-content-center align-items-center">
    <!-- Coluna para o formulário de login -->
    <div style="border: 7px solid #497dd7"
      class="shadow-box-community col-12 col-lg-8 col-md-10 col-sm-12 d-flex flex-column justify-content-center mb-md-0 mb-4 p-0">
      <img *ngIf="customBg" [src]="customBg" alt="" />
      <div class="shadow-box pt-2" style="background-color: #fff">
        <span class="text-normal-32-700 text-black" translate>Acessar</span>
        <div class="form-div m-0">
          <label for="user" class="label-text-inicio" translate>Email</label>
          <input type="email" name="user" id="user" class="form-input col-12 col-sm-12 col-md-10 col-lg-8"
            [formControl]="userEmail" />
        </div>
        <div class="form-div" *ngIf="foiEnviadoCodVerf">
          <label for="verification" class="label-text-inicio" translate>Código de Verificação</label>
          <app-codigo-verificacao></app-codigo-verificacao>
          <span class="text-reenviar-codigo d-inline-block mt-1 text-black" (click)="reenviarCodigo()"
            translate>Reenviar Código</span>
        </div>
        <button id="login-community-button" type="button" class="btn button-form w-100" (click)="entrar()">
          <span class="text-normal-16-700 text-white" translate>{{ buttonLabelEntrar }}</span>
        </button>
        <label class="pointer register-link" (click)="navegarCadastro()">Cadastre-se</label>
      </div>
    </div>
  </div>
</div>