<app-loading-spinner [bgTransparent]="true" *ngIf=" loading"></app-loading-spinner>


<div class="post-item mb-3">
    <div class="post-header">
        <div class="post-header-left">
            <div class="post-header-img">
                <img *ngIf="post.user.profilePicture && post.user.profilePicture.length > 0"
                    [src]="post.user.profilePicture" alt="profile-pic">
                <img *ngIf="!post.user.profilePicture" src="/assets/img/default_avatar_1.png" alt="profile-pic">
            </div>
            <div class="post-header-info">
                <span class="post-header-name pointer" (click)="openParticipantProfile($event, post.userId)">{{
                    post.user.name }}</span>
                <span class="post-header-date">{{ transformDate(post.createdAt) }}</span>
            </div>
        </div>
        <div class="post-header-right ">
            <span class="post-header-delete-post" [ngClass]="{'pointer': fromScreen === 'community-favorites'}">
                <i class="bi bi-bookmark"></i>
            </span>
        </div>
    </div>

    <div class="post-body">
        <div class="row-title">
            <h4 class="post-title">{{ post.title != 'RemoverPost' ? post.title : '' }}</h4>

            <div class="tag">Nome da Tag</div>
        </div>
        <p class="post-text" [innerHTML]="sanitizedPost"></p>
        <img *ngIf="post.photo && post.photo.length > 0" class="post-image" [src]="post.photo" alt="Imagem do post">

        <div class="post-pdf pointer" *ngIf="post.file" (click)="downloadFile(post.file)">
            <div class=" post-pdf-content">
                <span>{{extractFilenameFromUrl(post.file)}}</span>
                <i id="download" class="bi bi-download"></i>
            </div>
        </div>

        <div class="post-pdf pointer" *ngIf="post.movie" (click)="openVideoBlanket(post.movie)">
            <div class=" post-pdf-content">
                <span>Assista o video</span>
            </div>
        </div>

        <div class="post-likes" [ngStyle]="{ 'visibility': post.likes > 0 ? 'visible' : 'hidden' }">
            <div class="likes-info">
                <span class="mr-3">
                    {{
                    post.likes === 1
                    ? 'Curtida'
                    : post.likes > 1
                    ? 'Curtidas'
                    : ''
                    }}
                </span>
                <div class="likes-images-container">
                    <ng-container *ngIf="post.likes > 0">
                        <img *ngFor="let like of getLikesArray(); let i = index"
                            [src]="'../../../../assets/img/default_avatar_1.png'" [alt]="'Avatar ' + (i + 1)"
                            [class]="'like-img-' + i">
                    </ng-container>
                </div>
                <span *ngIf=" post.likes > 3" class="remain-quantity">+{{ post.likes - 3 }}</span>
                <a (click)="openModalSeePostLikes(post)">ver</a>
            </div>
        </div>



        <div class="post-options">
            <i *ngIf="!post.like" (click)="likePost()" class="bi bi-hand-thumbs-up "></i>
            <i *ngIf="post.like" (click)="unlikeCommentPost(post.like.id, 'post')"
                class="bi bi-hand-thumbs-up-fill "></i>


            <i (click)="commentId = 0;openModalInputPost()" class="bi bi-chat "></i>
            <i (click)="openModalCommunityMembers( post.id)" class="bi bi-share "></i>
        </div>

        <h4 *ngIf="commentsPost && commentsPost.length > 0" class="post-title mt-3" style="padding: 0 20px;">Comentários
        </h4>

        <div *ngIf="commentsPost && commentsPost.length > 0" class="comments-list">
            <app-comment *ngFor="let comment of commentsPost" (answerComment)="answerComment($event)"
                (likeComment)="likeCommentPost($event)" (unlikeComment)="unlikeCommentPost($event)"
                (deleteComment)="deleteComment($event)" [comment]="comment"
                [isUserCommunityOwner]="isUserCommunityOwner" [userProfile]="userProfile" [showDeleteButton]="false"
                [communityItem]="communityItem" [selectedChannel]="selectedChannel"></app-comment>
        </div>

    </div>
</div>