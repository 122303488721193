import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getHours, isAfter, isSameDay } from 'date-fns';
import { filter, firstValueFrom, map, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/new-sluper/core/service/loading.service';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../bell-notification/notification.service';
import { ModalCommunityMembersComponent } from '../communities/modal-community-members/modal-community-members.component';
import { CustomCommunityPhotosService } from '../custom-community-photos/custom-community-photos.service';
import { Connection, IConnection } from '../model/connection.model';
import { DocumentUserProfile } from '../model/document-user-profile.model';
import { EventParticipant, IEventWithParticipationStatus, SluperEvent } from '../model/event.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { IDocumentUserCSS } from '../new-sluper/core/model/document-user-css.model';
import { ToastService } from '../new-sluper/core/service/toast.service';
import { OpenEventService } from '../open-event.service';
import { OpenedCommunityService } from '../opened-community.service';
import { UserProfileService } from '../user-profile/user-profile.service';
import { DocumentUser } from './../model/document-user.model';
import { CreateCommunityModalComponent } from './create-community-modal/create-community-modal.component';
import { CreateEventModalComponent } from './create-event-modal/create-event-modal.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventTypeComponent } from './event-type/event-type.component';
import { EventsService, IEventById } from './events.service';
import { WelcomeCreateCommunityComponent } from './welcome-create-community/welcome-create-community.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() changeMenu = new EventEmitter<'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community'>();
  @Output() changeLogo = new EventEmitter<SluperEvent | null>();
  @Output() redirectToSettings = new EventEmitter<string | any>();
  @Input() userProfile!: DocumentUserProfile | undefined;
  @Input() documentUser!: DocumentUser | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() preferencesList!: IDocumentPreferences[];
  @Input() occupationsList!: IDocumentPreferences[];
  @Input() userCss!: IDocumentUserCSS;
  @Input() userProfileComplete: IUserProfileWithCSS | null = null;

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  @ViewChild('picker') picker!: MatDateRangePicker<Date>;


  canICreateCommunity = false;
  content: 'event-details' | 'event-list' | 'community-screen' | 'event-details-community-screen' = 'event-list';
  loadingEvents = true;
  loading = true;
  loadingMyCommunity = true;
  filteredCommunityEvents: {
    date: string;
    events: SluperEvent[];
  }[] = [];
  allEvents: SluperEvent[] = [];
  sectionEvents: SluperEvent[] = [];
  eventsParticipating: SluperEvent[] = [];
  allEventsParticipating: IEventWithParticipationStatus[] = [];
  allCommunitys: SluperEvent[] = [];
  allCommunitysFiltered: SluperEvent[] = [];
  allNextEvents: SluperEvent[] = [];
  userConnections: Connection[] = [];
  userConnectionsFiltered: Connection[] = [];
  userConnectionsFilteredThisMonth: Connection[] = [];
  pendingConnectionRequests: IConnection[] = [];
  nextEvents: IEventWithParticipationStatus[] = [];
  nextCommunitys: IEventWithParticipationStatus[] = [];
  ownerUserEvents: SluperEvent[] = [];
  eventsOrganizingByMe: SluperEvent[] = [];
  ownerUserCommunitys: SluperEvent[] = [];
  myCommunityItem: SluperEvent | null = null;
  communityItem: SluperEvent = {
    id: '',
    name: '',
    website: '',
    type: '',
    country: '',
    state: '',
    city: '',
    latitude: '',
    longitude: '',
    address: '',
    startDate: '',
    endDate: '',
    numberOfParticipants: 0,
    isPublic: false,
    description: '',
    imageLink: '',
    organizer: {
      idUser: '',
      name: '',
      uriImageProfile: ''
    },
    requiresConfirmation: false,
    guests: [],
    about: '',
    useCommunityLogo: false,
    communityLogo: null
  };
  openedEvent: SluperEvent | null = null;
  filterFields = {
    date: '',
    eventType: 'void',
  }
  private refreshUserInterval: any;

  public dateRange: any = { startDate: new Date().setHours(0, 0, 0, 0), endDate: new Date().setHours(23, 59, 59, 999) };
  // public dateRangeInput: any = { startDate: '', endDate: '', searchText: '' };
  public dateRangeForm: FormGroup;

  public isShowingDateRange = false;
  private scrollContainer!: HTMLElement;
  public showMoreMyNextEvents = false;
  public showMoreOthersEvents = false;
  public showMoreEventsOrganizedByYou = false;
  public showAllNextEvents = false;
  public showMoreConnectionRequests = false;

  public showMoreMyCommunities = false;
  public showMoreOthersCommunities = false;
  public isShowingMyCommunity = false;
  public participants: EventParticipant[] = [];

  datepickerOptions = {
    locale: {
      format: 'DD/MM/YYYY',
      cancelLabel: 'Cancelar',
      applyLabel: 'Aplicar'
    },
    singleDatePicker: true,
    showDropdowns: true,
    opens: 'center',
    drops: 'down',
    buttonClasses: ['btn', 'btn-primary']
  };
  isOpenModalCreateCommunity = false
  selectedTab = 1;
  defaultImageProfile = '/assets/img/imagem_perfil.png';
  public searchingCommunities = false
  public searchingEvents = false
  private subscriptionChangedNotification: Subscription | null = null;


  constructor(
    private modalService: NgbModal,
    private eventsService: EventsService,
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private openEventService: OpenEventService,
    private openedCommunityService: OpenedCommunityService,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService,
    private toastrService: ToastService,
    private customCommunityPhotosService: CustomCommunityPhotosService,




  ) {
    this.dateRangeForm = this.fb.group({
      startDate: [null],
      endDate: [null],
      searchText: ['']
    });
  }

  async ngOnInit(): Promise<void> {
    if (!this.subscriptionChangedNotification) {
      this.subscriptionChangedNotification = this.notificationService.notifications$.subscribe(async (data) => {
        if (data) {
          await this.fetchUserConnections()
        }
      });
    }

    await this.refreshData();

    const joinEvent = this.openEventService.getId();

    if (this.openedCommunityService.getCommunity() || joinEvent) {

      const eventDetails = joinEvent ? await firstValueFrom(this.eventsService.eventById(joinEvent)) : null;

      const communityDetails = this.openedCommunityService.getCommunity() ? await firstValueFrom(
        this.eventsService.eventById(this.openedCommunityService.getMyCommunity()?.id!)
      ) : null;

      if (communityDetails?.body?.event || eventDetails?.body?.event) {

        // let isParticipatingEvent = null
        // if (eventDetails?.body)
        //   isParticipatingEvent = eventDetails!.body!.participants.some(participant => participant.idUser === this.userProfile!.idUser);

        // let isParticipatingCommunity = null
        // if (communityDetails?.body)
        //   isParticipatingCommunity = communityDetails!.body!.participants.some(participant => participant.idUser === this.userProfile!.idUser);


        await this.getCommunityEventById(communityDetails, eventDetails)
        // if (isParticipatingEvent || isParticipatingCommunity) {
        // } else {
        //   this._alert.next('Erro ao buscar item, faça login e tente novamente');
        //   this.alertType = 'error';
        //   setTimeout(() => {
        //     this.authService.logout();
        //   }, 1500);
        //   return
        // }


      } else {
        this.toastrService.show('Erro ao buscar item, faça login e tente novamente', 'error');
        setTimeout(() => {
          this.authService.logout();
        }, 1500);
        return
      }

    }

    if (!joinEvent) {
      this.verifyDataAndOpenScreen()
    }
    this.loadingMyCommunity = false
    this.refreshUserInterval = setInterval(async () => {
      await this.fetchDataUser();
    }, 60000);
  }

  async getCommunityEventById(communityResp: HttpResponse<IEventById> | null, eventResp: HttpResponse<IEventById> | null) {
    try {
      this.loadingService.show('#fff');
      if (communityResp && communityResp.body || eventResp && eventResp.body) {

        const participe = (() => {
          if (eventResp && eventResp.body) {
            const eventType = eventResp.body.event.type;
            const parentEventId = eventResp.body.event.parentEvent;

            if (eventType === "COMUNIDADE") {
              this.loadingService.hide()
              return true;
            }

            if (this.nextCommunitys.some(ev => ev.event.id === parentEventId)) {
              this.loadingService.hide()
              return true;
            } else {
              this.toastrService.show('Erro ao buscar item, faça login e tente novamente', 'error');
              setTimeout(() => {
                this.authService.logout();
              }, 1000);
              return false
            }
          }
          // Qualquer outro caso retorna false
          return false;
        })();


        if (eventResp && eventResp.body && participe) {
          if (eventResp.body.event.type !== 'COMUNIDADE') {
            this.openedEvent = eventResp.body.event
            this.openModalShowEvent(communityResp && communityResp.body ? communityResp.body.event : null);
          } else {
            this.openCommunity(eventResp.body.event);
            //Colocar um set community no participate
            // colocar um set community no public-event
            // this._alert.next('Comunidade aberta com sucesso!');
            // this.alertType = 'success';
          }
        } else {
          if (communityResp && communityResp.body) {
            if (this.nextCommunitys.some(ev => ev.event.id === communityResp.body!.event.id
              && communityResp.body?.participants.some(participant => participant.idUser === this.userProfile!.idUser))) {
              this.openCommunity(communityResp.body.event);
            } else {
              this.openCommunity(communityResp.body.event);
              // this.toastrService.show('Voce não participa desta comunidade', 'error');
              // setTimeout(() => {
              //   this.authService.logout();
              // }, 1000);
            }



            this.openCommunity(communityResp.body.event);
            // this._alert.next('Comunidade aberta com sucesso!');
            // this.alertType = 'success';
          }
          // this.loadingService.hide()
        }


        // const event = resp.body.event
        // if (resp.body.event.type === 'COMUNIDADE') {
        //   if (!this.nextCommunitys.some(ev => ev.event.id === event.id)) {
        //     this.nextCommunitys.push({ event, participationStatus: 'PARTICIPANDO' });
        //   }

        //   this.openCommunity(event);
        //   this._alert.next('Comunidade aberta com sucesso!');
        //   this.alertType = 'success';
        // } else {
        //   this.openedEvent = event
        //   this.openModalShowEvent()
        //   this._alert.next('Evento aberto com sucesso!');
        //   this.alertType = 'success';
        // }

      } else {
        this.toastrService.show('O item solicitado nao foi encontrado', 'error');
        this.openEventService.removeId();
      }
      setTimeout(() => {
        this.loadingService.hide()
      }, 1000);
    } catch (error) {
      console.error('Error refreshing data', error);
    }
  }
  async refreshData() {
    try {
      this.loading = true;
      await Promise.all([
        // this.fetchCommunityEvents(),
        this.fetchFutureUserEvents(),
        this.fetchUserConnections(),
      ]);


      await this.fetchUserPendingContactsRequests()
    } catch (error) {
      console.error('Error refreshing data', error);
    } finally {
      this.loading = false;
    }
  }
  private async fetchUserPendingContactsRequests(): Promise<void> {
    try {

      if (!this.userProfile?.idUser) {
        return;
      }
      const connectionsRequests = await firstValueFrom(this.eventsService.getPendingConnectionRequests(this.userProfile?.idUser));
      if (!connectionsRequests.body) {
        return;
      }
      this.pendingConnectionRequests = connectionsRequests.body;
    } catch (error) {
      console.error(error);
    }
  }

  verifyDataAndOpenScreen() {
    if (this.nextCommunitys.length === 1) {
      // this.openCommunity(this.nextCommunitys[0], 'community-screen');
    } else if (this.nextCommunitys.length > 1) {
      const myCommunity = this.nextCommunitys.find(event => event.event.organizer.idUser === this.userProfile?.idUser)
      if (myCommunity) {
        //Bloqueado pois vai abrir so se tiver 1 comunidade
        // this.openCommunity(myCommunity, 'community-screen');
      }
    }
  }

  ngAfterViewInit() {
    this.scrollContainer = document.querySelector('.next-events-see-more-visualization') as HTMLElement;

    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('wheel', this.handleScroll);
    }
  }

  handleScroll = (event: WheelEvent) => {
    const isScrollingUp = event.deltaY < 0;

    if (
      (isScrollingUp && this.scrollContainer.scrollTop === 0) ||
      (!isScrollingUp && this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop === this.scrollContainer.clientHeight)
    ) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('wheel', this.handleScroll);
    }

    if (this.subscriptionChangedNotification) {
      this.subscriptionChangedNotification.unsubscribe();
      this.subscriptionChangedNotification = null;
    }
    if (this.refreshUserInterval) {
      clearInterval(this.refreshUserInterval);
    }
  }

  fillCommunityEvents(events: SluperEvent[]) {
    this.filteredCommunityEvents = [];

    const formattedFieldDate = this.filterFields.date ? new Date(Number(this.filterFields.date.split('-').reverse()[0]), Number(this.filterFields.date.split('-').reverse()[1]) - 1, Number(this.filterFields.date.split('-').reverse()[2])) : null;

    let filteredEvents = events;

    if (formattedFieldDate) {
      filteredEvents = events.filter(e => isSameDay(new Date(e.startDate), new Date(formattedFieldDate)));
    }

    if (this.filterFields.eventType !== 'void') {
      filteredEvents = filteredEvents.filter(e => e.type === this.filterFields.eventType);
    }

    filteredEvents.forEach(event => {
      const eventDate = event.startDate.split('T')[0] as string;

      const eventDateIndex = this.filteredCommunityEvents.findIndex((event) => event.date === eventDate);

      if (eventDateIndex !== -1) {
        this.filteredCommunityEvents[eventDateIndex].events.push(event);

        return;
      }

      this.filteredCommunityEvents.push({
        date: eventDate,
        events: [event]
      });
    });

    this.filteredCommunityEvents.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  async fetchFutureUserEvents() {
    this.loadingEvents = true;

    if (!this.userProfile) {
      this.loadingEvents = false;
      return;
    }

    try {
      const response = await this.eventsService.getFutureEvents(this.userProfile.idUser).toPromise();

      if (!response?.body) {
        return;
      }
      const allEvents = response.body.allEvents as SluperEvent[];
      const eventsParticipating = response.body.eventsParticipanting as IEventWithParticipationStatus[];
      const eventsOrganizingByMe = response.body.eventsOrganizing as SluperEvent[];
      this.allEventsParticipating = eventsParticipating
      this.eventsParticipating = eventsParticipating
        .filter(item => item.event && item.event.type !== 'COMUNIDADE')
        .map(item => item.event);

      this.allEvents = allEvents.filter(ev => ev.type !== 'COMUNIDADE');
      this.allCommunitys = allEvents.filter(ev => ev.type === 'COMUNIDADE');
      this.communityItem = allEvents.find(ev => ev.id === this.communityItem.id) || this.communityItem;

      this.myCommunityItem = this.allCommunitys.find(ev => ev.organizer.idUser === this.userProfile?.idUser) || null
      this.handleChangeLogo(this.myCommunityItem!);

      const isShowCreateCommunity = localStorage.getItem('isShowCreateCommunity')
      const eventId = this.openEventService.getId();
      if (!eventId && !isShowCreateCommunity && this.myCommunityItem === null && this.userProfilePlan?.planName === "Plano Corporativo" && !this.isOpenModalCreateCommunity) {
        this.openModalCreateCommunity()
      }
      this.sectionEvents = this.allEvents.filter(ev => ev.isPublic === true && new Date(ev.endDate) > new Date())
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

      this.allNextEvents = this.eventsParticipating
        .filter(ev => new Date(ev.endDate) > new Date())
        .sort((a: SluperEvent, b: SluperEvent) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

      this.canICreateCommunity = !this.allCommunitys.some(ev => ev.organizer.idUser === this.userProfile?.idUser);
      this.fillCommunityEvents(this.allEvents);

      this.nextEvents = eventsParticipating.filter(ev => ev.event.type !== 'COMUNIDADE' && isAfter(new Date(ev.event.startDate), new Date()));
      this.nextCommunitys = eventsParticipating.filter(ev => ev.event.type === 'COMUNIDADE');
      this.allCommunitysFiltered = this.allCommunitys.filter(ev => ev.isPublic === true && !this.nextCommunitys.find(ev2 => ev2.event.id === ev.id));

      this.eventsOrganizingByMe = eventsOrganizingByMe;
      this.ownerUserEvents = eventsOrganizingByMe.filter(ev => ev.type !== 'COMUNIDADE' && new Date(ev.endDate) > new Date())
        .sort((a: SluperEvent, b: SluperEvent) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

      this.ownerUserCommunitys = eventsOrganizingByMe.filter(ev => ev.type === 'COMUNIDADE');

    } catch (error) {
      console.error('Error fetching future user events', error);
    } finally {
      this.loadingEvents = false;
    }
  }

  handleChangeLogo(community: SluperEvent) {
    // this.changeLogo.emit(community);
    // this.customCommunityPhotosService.setLogo(community?.communityLogo ?? '')
  }


  async fetchUserConnections(silenceRefresh: boolean = false) {
    if (!silenceRefresh) {
      this.loadingEvents = true;
    }

    if (!this.userProfile) {
      this.loadingEvents = false;
      return;
    }

    try {
      const response = await this.eventsService.getUserConnections(this.userProfile.idUser).toPromise();
      const userConnections = response && response.body ? response.body.filter(connection => !connection.indication) : null;

      if (!userConnections) {
        return;
      }

      this.userConnections = userConnections;


      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setHours(0, 0, 0, 0);
      thirtyDaysAgo.setDate(today.getDate() - 30);

      const participants = this.userConnections.filter(connection => {
        const connectionDate = new Date(connection.connectionDate);
        connectionDate.setHours(0, 0, 0, 0);
        return connectionDate >= thirtyDaysAgo && connectionDate <= today;
      });

      this.userConnectionsFilteredThisMonth = participants;
    } catch (error) {
      console.error('Error fetching user connections', error);
    } finally {
      this.loadingEvents = false;
    }
  }

  openCommunityMembersModal(type: 'add' | 'connect' | 'show') {
    const dialogRef = this.dialog.open(ModalCommunityMembersComponent, {
      data: {
        participants: this.participants.filter(
          participant => participant.idUser !== this.userProfile!.idUser
        ),
        userProfile: this.userProfile,
        userProfilePlan: this.userProfilePlan,
        userConnections: this.userConnections.filter(
          connection => connection.connection.idUser !== this.userProfile!.idUser
        ),
        type: type,
        communityItem: this.communityItem,
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
      },
      error => {
        console.error('Erro ao fechar o modal: ', error);
      }
    );
  }

  // filterMembersCommunity(event: any) {
  //   if (event.target.value.length === 0) {
  //     this.userConnectionsFiltered = this.userConnections;
  //   } else {
  //     this.userConnectionsFiltered = this.userConnections.filter((person: Connection) => person.connection.name.toLowerCase().includes(event.target.value.toLowerCase()));
  //   }
  // }

  handleEventClick(event: SluperEvent) {
    this.openedEvent = event;
    // this.content = 'event-details';
    this.openModalShowEvent()
  }

  openModalShowEvent(event?: SluperEvent | null) {
    const dialogRef = this.dialog.open(EventDetailsComponent, {
      data: {
        authorizedPage: true,
        userProfile: this.userProfile,
        currentUserConnections: this.userConnections,
        preferencesList: this.preferencesList,
        occupationsList: this.occupationsList,
        userProfilePlan: this.userProfilePlan,
        eventId: this.openedEvent!.id,
        documentUser: this.documentUser,
        userProfileComplete: this.userProfileComplete
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.openEventService.removeId();
      if (result && typeof (result) === 'object') {
        this.openCreateEventModal('PRESENCIAL', result);
      }
      if (event) {
        this.openCommunity(event);
      }
      await this.refreshData()
    }, error => {
      console.error('Erro ao fechar o modal:', error);
    });
  }

  openModalCreateCommunity() {
    this.isOpenModalCreateCommunity = true
    const dialogRef = this.dialog.open(WelcomeCreateCommunityComponent, {
      data: {},
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      this.isOpenModalCreateCommunity = false
      if (result) {
        this.openCreateCommunityModal()
      } else {
        localStorage.setItem('isShowCreateCommunity', 'true')
      }
    }, error => {
      console.error('Erro ao fechar o modal:', error);
    });
  }

  async handleBackToList() {
    this.openedEvent = null;
    this.content = 'event-list';
    await this.refreshData()
  }

  openEventTypeModal() {
    const modalRef = this.modalService.open(EventTypeComponent, { centered: true });
    modalRef.result.then((result: { status: boolean, response?: 'PRESENCIAL' | 'REMOTO' }) => {
      if (result.status && result.response) {
        this.openCreateEventModal(result.response);
      }
    }).catch((error) => {

    });
  }


  openCreateEventModal(type: 'PRESENCIAL' | 'REMOTO', event?: SluperEvent) {
    const modalRef = this.modalService.open(CreateEventModalComponent, { centered: true, size: "xl" });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.userConnections = this.userConnections;
    modalRef.componentInstance.communityItem = this.myCommunityItem;
    modalRef.componentInstance.event = event ? event : null;
    modalRef.componentInstance.eventType = 'COMMUNITY-EVENT';
    modalRef.componentInstance.triggerfeedbackMessage.subscribe(async (alert: { message: string; type: 'success' | 'error' }) => {
      this.toastrService.show(alert.message, alert.type)
      if (alert.type === 'success') {
        this.filterFields = {
          date: '',
          eventType: 'void',
        }
        await this.refreshData()
      }
    })
  }

  openCreateCommunityModal() {
    const modalRef = this.modalService.open(CreateCommunityModalComponent, { centered: true, size: "xl" });

    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.userConnections = this.userConnections;
    modalRef.componentInstance.triggerfeedbackMessage.subscribe(async (alert: { message: string; type: 'success' | 'error' }) => {
      this.toastrService.show(alert.message, alert.type)
      if (alert.type === 'success') {
        this.filterFields = {
          date: '',
          eventType: 'void',
        };
        await this.refreshData();
        modalRef.result.then(
          async (data: { type: 'none' | 'openModal', id?: string }) => {

            if (data.type === 'openModal' && data.id) {
              const event = this.allCommunitys.find((event) => event.id === data.id);
              if (event) {
                this.openCommunity(event);
              }
              setTimeout(() => {
                this.refreshData();
              },);
            }
          },
          (reason) => {
            console.error('Modal fechado sem ID', reason);
          }
        );
      }
    });

  }


  handleFilterDateChange(_date: object) {
    this.fillCommunityEvents(this.allEvents);
  }

  handleFilterEventTypeChange(event: any) {
    this.filterFields.eventType = event.target.value;
    this.fillCommunityEvents(this.allEvents);
  }

  formatDate(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
    this.fillCommunityEvents(this.allEvents);
  }

  handleShareEvent(e: any, event: SluperEvent) {
    e.stopPropagation();

    const shareData = {
      title: event.name,
      text: `Venha participar do ${event.name} em ${new Date(event.startDate).toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })} às ${getHours(new Date(event.startDate))}! Será em ${event.address}. Confira mais detalhes e confirme sua presença. Te esperamos lá!`,
      url: `https://perfil.sluper.bio/public-event/${event.id}`
    };

    if (!navigator.share) {
      return;
    }

    navigator.share(shareData);
  }

  changeSelectedTab(tab: number) {
    const myCommunity = this.nextCommunitys.find(event => event.event.organizer.idUser === this.userProfile?.idUser)
    this.selectedTab = tab;

    switch (tab) {
      case 0:
        this.content = 'event-list';
        break;
      case 1:
        this.content = this.isShowingMyCommunity ? 'event-details-community-screen' : 'event-list';
        if (myCommunity) {
          //Bloqueado pois vai abrir so se tiver 1 comunidade
          this.openCommunity(myCommunity, 'community-screen');
        }
        break;
    }

  }

  async openConnectionProfile(connection: Connection) {
    window.open(`https://sluper.bio/${connection.connection.idUser}`);
  }

  joinEvent(event: SluperEvent | IEventWithParticipationStatus) {
    if (this.isSluperEvent(event)) {
      this.handleEventClick(event);
    } else {
      this.handleEventClick(event.event)
    }
  }

  joinCommunity(event: SluperEvent | any) {
    this.openCommunity(event);
  }

  isSluperEvent(event: any): event is SluperEvent {
    return event && 'startDate' in event && 'endDate' in event;
  }

  scrollLeft(type: string) {
    const cardBody = document.querySelector(`.${type} .card-body`);
    if (cardBody) {
      cardBody.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }

  scrollRight(type: string) {
    const cardBody = document.querySelector(`.${type} .card-body`);
    if (cardBody) {
      cardBody.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }

  searchCommunities(event: any) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    input.value = value;
    if (value.length === 0) {
      this.allCommunitysFiltered = this.allCommunitys.filter(ev => ev.isPublic === true && !this.nextCommunitys.find(ev2 => ev2.event.id === ev.id));
      this.nextCommunitys = this.allEventsParticipating.filter(ev => ev.event.type === 'COMUNIDADE');
      this.searchingCommunities = false;
      return
    }
    // this.searchingCommunities = true;
    this.allCommunitysFiltered = this.allCommunitys.filter(ev => ev.name.toLowerCase().includes(value.toLowerCase()) && ev.isPublic === true && !this.nextCommunitys.find(ev2 => ev2.event.id === ev.id));
    this.nextCommunitys = this.allEventsParticipating.filter(ev => ev.event.type === 'COMUNIDADE' && ev.event.name.toLowerCase().includes(value.toLowerCase()));

    // if (this.allCommunitysFiltered.length === 0) {
    //   this.alertType = 'error';
    //   this._alert.next(
    //     'Nenhum resultado encontrado. Tente outro termo de busca ou revise o nome do evento.'
    //   );
    // }
  }

  mudouMenu(event: any) {
    this.changeMenu.emit(event);
  }

  async fetchData(event: any) {
    this.loadingEvents = true;

    try {
      const userData = await firstValueFrom(this.userProfileService
        .userById(null)
        .pipe(
          filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
          map((response: HttpResponse<IUserProfileWithCSS>) => response.body),
        )
      )

      this.userProfile = userData?.documentUserProfile;

      const userPlan = await firstValueFrom(this.userProfileService
        .getUserPlan()
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
      );

      await this.fetchUserConnections();
      await this.refreshData()
      this.userProfilePlan = userPlan;

      this.loadingEvents = false;
    } catch (e) {
      this.loadingEvents = false;

      this.toastrService.show('Nao foi possivel buscar as informacoes do perfil. Contacte o administrador.', 'error');
    }
  }


  async fetchDataUser() {

    try {
      await this.fetchUserConnections(true);
      this.fetchUserPendingContactsRequests();
    } catch (e) {
      if (this.refreshUserInterval) {
        clearInterval(this.refreshUserInterval);
      }
      this.toastrService.show('Nao foi possivel buscar as informacoes do perfil. Contacte o administrador.', 'error');
    }
  }


  linkRequired(value: any) {
    // this.disableMenu = value;
  }

  openCommunity(
    event: IEventWithParticipationStatus | SluperEvent,
    type: 'event-details' | 'event-list' | 'community-screen' | 'event-details-community-screen' = 'community-screen'
  ) {
    this.content = type;
    if (this.isSluperEvent(event)) {
      this.communityItem = event;
      this.handleChangeLogo(this.communityItem);
    } else {
      this.communityItem = event.event;
      this.handleChangeLogo(this.communityItem);
    }
  }

  onDateChange(
    event: MatDatepickerInputEvent<Date> | any,
    type: 'start' | 'end' | 'text',
    inputType: 'text' | 'date'
  ) {
    if (inputType === 'date') {
      if (type === 'start') {
        this.dateRangeForm.get('startDate')?.setValue(event.value);
      } else if (type === 'end') {
        this.dateRangeForm.get('endDate')?.setValue(event.value);
      }
    } else if (inputType === 'text') {
      const input = event.target as HTMLInputElement;
      this.dateRangeForm.get('searchText')?.setValue(input.value);
    }

    let filteredEvents = this.allEvents.filter(ev => new Date(ev.endDate) > new Date());
    let allNextEvents = this.eventsParticipating.filter(ev => ev.type !== 'COMUNIDADE' && new Date(ev.endDate) > new Date());
    let ownerUserEvents = this.eventsOrganizingByMe.filter(ev => ev.type !== 'COMUNIDADE' && new Date(ev.endDate) > new Date());

    const startDate = this.dateRangeForm.get('startDate')?.value;
    const endDate = this.dateRangeForm.get('endDate')?.value;
    const searchText = this.dateRangeForm.get('searchText')?.value;

    if (startDate && endDate) {
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      filteredEvents = filteredEvents.filter((event: SluperEvent) => {
        const eventStartDate = new Date(event.startDate);
        return (
          eventStartDate.getTime() >= startOfDay.getTime() &&
          eventStartDate.getTime() <= endOfDay.getTime()
        );
      });

      allNextEvents = allNextEvents.filter((event: SluperEvent) => {
        const eventStartDate = new Date(event.startDate);
        return (
          eventStartDate.getTime() >= startOfDay.getTime() &&
          eventStartDate.getTime() <= endOfDay.getTime()
        );
      });

      ownerUserEvents = ownerUserEvents.filter((event: SluperEvent) => {
        const eventStartDate = new Date(event.startDate);
        return (
          eventStartDate.getTime() >= startOfDay.getTime() &&
          eventStartDate.getTime() <= endOfDay.getTime()
        );
      });
    }

    if (searchText && searchText.length > 0) {
      filteredEvents = filteredEvents.filter((event: any) =>
        event.name.toLowerCase().includes(searchText)
      );

      allNextEvents = allNextEvents.filter((event: any) =>
        event.name.toLowerCase().includes(searchText)
      )
        .sort((a: SluperEvent, b: SluperEvent) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

      ownerUserEvents = ownerUserEvents.filter((event: any) =>
        event.name.toLowerCase().includes(searchText)
      );
    }

    this.sectionEvents = filteredEvents.filter((event: SluperEvent) =>
      event.name.toLowerCase().includes(searchText) && event.isPublic === true && new Date(event.endDate) > new Date()
    ).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

    this.allNextEvents = allNextEvents.filter((event: SluperEvent) =>
      event.name.toLowerCase().includes(searchText) && new Date(event.endDate) > new Date())
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

    this.ownerUserEvents = ownerUserEvents.filter((event: SluperEvent) =>
      event.name.toLowerCase().includes(searchText) && new Date(event.endDate) > new Date())
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

    this.isShowingDateRange = startDate && endDate ? true : false;
  }

  clearFilter() {
    this.isShowingDateRange = false;

    const searchText = this.dateRangeForm.get('searchText')?.value;
    if (searchText && searchText.length > 0) {
      this.dateRangeForm.get('startDate')?.setValue(null);
      this.dateRangeForm.get('endDate')?.setValue(null);

      this.sectionEvents = this.allEvents.filter((event: SluperEvent) =>
        event.name.toLowerCase().includes(searchText) && event.isPublic === true && new Date(event.endDate) > new Date()
      ).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

      this.allNextEvents = this.eventsParticipating.filter((event: SluperEvent) =>
        event.name.toLowerCase().includes(searchText) && new Date(event.endDate) > new Date()
      ).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())


      this.ownerUserEvents = this.eventsOrganizingByMe.filter((event: SluperEvent) =>
        event.type !== 'COMUNIDADE' && event.name.toLowerCase().includes(searchText) && new Date(event.endDate) > new Date()
      ).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())


    } else {
      this.sectionEvents = this.allEvents.filter(ev => ev.isPublic === true && new Date(ev.endDate) > new Date())
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

      this.allNextEvents = this.eventsParticipating.filter((event: SluperEvent) =>
        new Date(event.endDate) > new Date()
      ).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

      this.ownerUserEvents = this.eventsOrganizingByMe.filter((event: SluperEvent) =>
        new Date(event.endDate) > new Date() && event.type !== 'COMUNIDADE'
      ).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
      this.dateRangeForm.reset();
    }
  }

  amIParticipatingEvent(event: SluperEvent): boolean {
    return this.ownerUserEvents.find((ev) => ev.id === event.id) ? true : false
  }

  amIParticipatingAllEvent(event: SluperEvent): boolean {
    return this.allEvents.find((ev) => ev.id === event.id) ? true : false
  }

  blockInput(event: Event, type: 'start' | 'end'): void {
    event.preventDefault();
  }

  canCreateCommunity(): boolean {
    return this.canICreateCommunity && this.userProfilePlan?.planName === "Plano Corporativo"
  }

  canCreateEvent(): boolean {
    if (!this.myCommunityItem) return false
    return !this.canCreateCommunity() && this.myCommunityItem && this.userProfilePlan?.planName === "Plano Corporativo"
  }


  alertsCommunity(event: { message: string; type: 'success' | 'error'; }) {
    this.toastrService.show(event.message, event.type);
  }

  backToAllCommunities() {
    this.content = 'event-list'
    this.handleChangeLogo(this.myCommunityItem!);
    this.refreshData()
  }

  handleRedirectToSettings(type: string) {
    this.redirectToSettings.emit(type);
  }

}
