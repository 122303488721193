<app-loading-spinner [customBg]="'transparent'" *ngIf="loadingConnections"></app-loading-spinner>

<div class="community-members-screen">
  <div class="mb-3 d-flex justify-content-between">
    <!-- Títulos variam de acordo com o tipo (add, show, delete, connect, share) -->
    <h4 *ngIf="type === 'add'" class="modal-community-info-title">Convidar pessoas</h4>
    <h4 *ngIf="type === 'show'" class="modal-community-info-title">Ver pessoas</h4>
    <h4 *ngIf="type === 'delete'" class="modal-community-info-title">Remover pessoas</h4>
    <h4 *ngIf="type === 'connect'" class="modal-community-info-title">Conectar com pessoas</h4>
    <h4 *ngIf="type === 'share'" class="modal-community-info-title">Compartilhar</h4>
    <i class="bi bi-x close pointer" (click)="closeModal()"></i>
  </div>

  <!-- Opção CSV (somente quando type === 'add') -->
  <div class="search-person-container" *ngIf="type === 'add' && !loadingConnections">
    <label class="input-label mb-3"> Convidar pessoas para a comunidade: </label>

    <div class="d-flex align-items-center">
      <span>Importe um arquivo CSV</span>
      <button *ngIf="!csvFile" (click)="triggerFileInput()" class="highlight-button">
        Selecionar
      </button>
    </div>

    <!-- Se já existe csvFile, mostra nome + botões -->
    <div class="d-flex align-items-center" *ngIf="csvFile">
      <i class="bi bi-file-earmark-arrow-down" style="font-size: 1.5rem; color: black;"></i>
      <span>{{ csvFile?.name }}</span>

      <button (click)="removeFile()" class="highlight-button-cancel">Cancelar</button>
      <button (click)="sendInvitesByCsv()" class="highlight-button">Enviar</button>
    </div>
  </div>

  <!-- Convite de usuário individual por email (somente em type='add') -->
  <div class="search-person-container" *ngIf="type === 'add' && !loadingConnections">
    <div class="d-flex align-items-center">
      <input type="email" [(ngModel)]="formModel.email" (ngModelChange)="validateEmail()" maxlength="50"
        placeholder="exemplo@gmail.com" class="form-control w-50" />
      <button (click)="invitePersonToCommunityByEmail($event)" [disabled]="!formModel.isValid" class="highlight-button">
        Convidar
      </button>
    </div>
    <span class="error-message" *ngIf="formModel.errorMessage">
      {{ formModel.errorMessage }}
    </span>
  </div>

  <!-- Filtro de participantes (para delete, show, share) -->
  <div class="search-person-container" *ngIf="(type === 'show' || type === 'share' || type === 'delete')
            && !loadingConnections
            && allParticipants.length > 0">
    <label class="input-label">Procurar pessoas por nome:</label>
    <input type="text" maxlength="50" placeholder="Procurar pessoas por nome" class="form-control w-50 mb-3"
      (input)="filterConnections($event)" />
  </div>

  <!-- Filtro de participantes (para connect) -->
  <div class="search-person-container" *ngIf="(type === 'connect')
            && !loadingConnections
            && allParticipants.length > 0">
    <label class="input-label">Procurar pessoas por nome:</label>
    <input type="text" maxlength="50" placeholder="Procurar pessoas por nome" class="form-control w-50 mb-3"
      (input)="filterConnections($event)" />
  </div>

  <!-- Lista de participantes -->
  <div class="vertical-connections-list-container">
    <div class="participants-list" *ngIf="!loadingConnections && participants.length > 0">
      <div class="card" *ngFor="let connection of participants; let i = index">
        <div class="connection-card" (click)="openConnectionProfile(connection.idUser)">
          <!-- "Cabeçalho" do cartão com background e botões -->
          <div class="connection-calling-card" [style.background-image]="'url(' + getBackground(connection) + ')'">
            <div class="profile-img">
              <img [src]="connection.uriImageProfile ? connection.uriImageProfile : defaultImageProfile"
                alt="connection-profile-pic" class="connection-profile-pic" />
            </div>
            <div class="connection-actions">
              <button (click)="generateVcardConnections($event, connection)" class="connection-action-button"
                matTooltip="Gerar v-card">
                <i class="bi bi-person-vcard"></i>
              </button>
              <button (click)="checkConnectionInterests($event, connection)" class="connection-action-button"
                matTooltip="Ver interesses">
                <i class="bi bi-person-lines-fill"></i>
              </button>
              <button (click)="checkConnectionPresentation($event, connection)" class="connection-action-button"
                matTooltip="Ver apresentação">
                <i class="bi bi-person-workspace"></i>
              </button>
            </div>
          </div>

          <!-- Dados básicos do participante -->
          <div class="connection-headline">
            <div class="connection-infos">
              <span class="connection-name">{{ connection.name }}</span>
              <span class="connection-bio" [innerHTML]="connection.bio"></span>
            </div>
          </div>

          <!-- Botões de ação variam conforme "type" -->
          <div class="indication-connection-actions-container" *ngIf="type !== 'show'">
            <!-- CONNECT -->
            <button *ngIf="type === 'connect'
                      && !isParticipantMyConnection(connection)
                      && !isUserSentConnection(connection.idUser)" class="highlight-button"
              (click)="handleConnect($event, connection.idUser, connection, i)">
              <i class="bi bi-person-fill-add"></i>
              Conectar
            </button>

            <button *ngIf="type === 'connect'
                      && !isParticipantMyConnection(connection)
                      && isUserSentConnection(connection.idUser)" class="highlight-button"
              (click)="handleConnect($event, connection.idUser, connection, i)" disabled>
              <i class="bi bi-person-fill-add"></i>
              Pendente
            </button>

            <!-- ADD -->
            <button *ngIf="type === 'add'" class="highlight-button"
              (click)="invitePersonToCommunity($event, connection, i)">
              <i class="bi bi-person-fill-add"></i>
              Convidar
            </button>

            <!-- SHARE -->
            <button *ngIf="type === 'share'" class="highlight-button" (click)="shareWith($event, connection.idUser)">
              <i class="bi bi-person-fill-add"></i>
              Compartilhar
            </button>

            <!-- DELETE -->
            <button id="delete-button" *ngIf="type === 'delete'" class="highlight-button"
              (click)="removeParticipant($event, connection, i)">
              <i class="bi bi-trash"></i>
              Remover
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="no-contacts-dispplayed" *ngIf="participants.length < 1">
      <span class="no-contacts-dispplayed-label">Nenhuma pessoa encontrada</span>
    </div>
  </div>
</div>