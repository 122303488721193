import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { EventsService } from 'src/app/events/events.service';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';

import { ModalCommunityMembersComponent } from '../../modal-community-members/modal-community-members.component';
import { ModalDeletePostComponent } from '../../post/modal-delete-post/modal-delete-post.component';
import { ModalPostInputComponent } from '../../post/modal-post-input/modal-post-input.component';
import { Comment, Like, Post, PostsService } from '../../post/posts.service';
import { PeopleLikedPostModalComponent } from '../people-liked-post-modal/people-liked-post-modal.component';


@Component({
  selector: 'app-post-component',
  templateUrl: './post-component.component.html',
  styleUrls: ['./post-component.component.css']
})
export class PostComponentComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  @Input() fromScreen!: 'community-favorites' | 'community-mentions';
  @Input() post!: Post;
  @Input() isUserCommunityOwner: boolean = false
  @Input() userProfile: DocumentUserProfile | undefined
  @Input() communityItem!: SluperEvent
  @Input() selectedChannel!: ChannelInterface | null
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() participants: EventParticipant[] = [];
  @Output() refreshPosts = new EventEmitter();
  @Output() deletePost = new EventEmitter<number>();
  @Output() refreshInteractions = new EventEmitter();

  // @Output() createdCommentLike = new EventEmitter<Like>();

  public sanitizedPost: SafeHtml = '';

  commentsPost: Comment[] = []
  commentId: number = 0
  public loading = false

  constructor(
    private eventsService: EventsService,
    private postsService: PostsService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private toastrService: ToastService


  ) { }


  ngOnInit(): void {
    this.commentsPost = this.post.comments.filter((comment: Comment) => comment.replyTo === null)
      .sort((a: Comment, b: Comment) => b.createdAt.localeCompare(a.createdAt));
    this.commentsPost.forEach((comment: Comment) => {
      comment.answers = this.post.comments.filter((reply: Comment) => reply.replyTo === comment.id)
    })

    this.sanitizedPost = this.sanitizer.bypassSecurityTrustHtml(
      this.post.content || ''
    );
  }

  openDeleteSidebarItemModal(id: number, type: 'comment' | 'post') {
    const dialogRef = this.dialog.open(ModalDeletePostComponent, {
      data: { id: id, type: type },
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
        if (result && result.success) {
          if (type === 'comment') {
            this.removeCommentById(id);
          } else if (type === 'post') {
            this.deletePost.emit(id)
          }
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }
  getLikesArray(): number[] {
    return Array(this.post.likes <= 3 ? this.post.likes : 3).fill(0);
  }

  private removeCommentById(commentId: number) {
    const mainCommentIndex = this.commentsPost.findIndex(
      (comment) => String(comment.id) === String(commentId)
    );
    if (mainCommentIndex !== -1) {
      this.commentsPost.splice(mainCommentIndex, 1);
      return;
    }

    for (const comment of this.commentsPost) {
      if (comment.answers) {
        const nestedCommentIndex = comment.answers.findIndex(answer => answer.id === commentId);
        if (nestedCommentIndex !== -1) {
          comment.answers.splice(nestedCommentIndex, 1);
          return;
        }
      }
    }

    console.warn(`Comentário com ID ${commentId} não encontrado.`);
  }



  handleRefreshPosts() {
    this.refreshPosts.emit()
  }

  transformDate(date: string | Date): string {
    const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const data = new Date(date);
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    return `${dia} de ${mes} às ${hora}:${minutos}`;
  }

  downloadFile(link: string) {
    try {
      const a = document.createElement('a');
      a.href = link;
      a.download = this.extractFilenameFromUrl(link);
      a.target = '_blank';
      a.click();

    } catch (error) {
      console.error('Erro ao tentar baixar o arquivo:', error);
    }
  }


  openVideoBlanket(link: string) {
    window.open(link, '_blank');
  }

  extractFilenameFromUrl(url: string): string {
    return url.split('/').pop() || '';
  }

  answerComment(event: number) {
    this.commentId = event
    this.openModalInputPost()
  }
  openModalInputPost() {
    const modalRef = this.modalService.open(ModalPostInputComponent, {
      centered: true,
    });

    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.communityItem = this.communityItem;
    modalRef.componentInstance.selectedChannel = this.selectedChannel;
    modalRef.componentInstance.postId = this.post.id;
    modalRef.componentInstance.commentId = this.commentId;
    modalRef.componentInstance.inputType = 'comment';

    modalRef.result
      .then(async (result: { result: boolean, post: Post | null, comment: Comment | null }) => {
        if (result.result && result.comment) {
          const commentData = result.comment;

          if (commentData.replyTo === null) {
            if (!this.commentsPost) {
              this.commentsPost = [];
            }
            this.commentsPost = [...this.commentsPost, commentData];
            this.commentsPost.sort((a: Comment, b: Comment) => b.createdAt.localeCompare(a.createdAt));
          } else {
            const parentComment = this.commentsPost.find((comment: Comment) => comment.id === commentData.replyTo);
            if (parentComment) {
              parentComment.answers = [...(parentComment.answers || []), commentData];
              this.commentsPost = [...this.commentsPost];
              this.commentsPost.sort((a: Comment, b: Comment) => b.createdAt.localeCompare(a.createdAt));
            }
          }
        }
      })
      .catch(error => {
        console.error('Modal dismissed with error:', error);
      });
  }
  openModalSeePostLikes(post: Post) {
    const dialogRef = this.dialog.open(PeopleLikedPostModalComponent, {
      data: { post },
      width: '400px'
    });

    // Lidando com o resultado após fechar o diálogo
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // console.log('Dialog fechado com resultado:', result);
      } else {
        // console.log('Dialog foi fechado sem resultado');
      }
    });
  }


  deleteComment(id: number) {
    this.openDeleteSidebarItemModal(id, 'comment')
  }


  async sharePost(postId: number, receiverId: string) {
    this.loading = true
    try {
      const data = {
        "senderId": this.userProfile!.idUser,
        "receiverId": receiverId,
        "eventPostId": postId
      }
      await firstValueFrom(this.postsService.sharePost(data))

      this.toastrService.show('Post compartilhado com sucesso.', 'success');
      this.loading = false

    } catch (error) {
      console.error(error)
      this.loading = false
      this.toastrService.show('Ocorreu um erro ao tentar compartilhar o post. Tente novamente mais tarde.', 'error');

    }
  }
  async likePost() {
    this.loading = true;
    try {
      const data: { idPost: number | null, idUser: string, type: string } = {
        idPost: this.post.id,
        idUser: this.userProfile!.idUser,
        type: 'LIKE'
      };
      const resp = await firstValueFrom(this.postsService.createPostLike(data));

      if (resp.body && resp.body.id) {
        this.createdPostLike(resp.body)
      }
      // this._alert.next('Post curtido com sucesso.');
      // this.alertType = 'success';
      this.refreshInteractions.emit()
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;

      this.toastrService.show('Ocorreu um erro ao tentar curtir o post. Tente novamente mais tarde.', 'error');
    }
  }

  async likeCommentPost(commentId: number) {
    this.loading = true;
    try {
      const data: { idPost: number | null, idComment: number | null, idUser: string, type: string } = {
        idPost: this.post.id,
        idComment: commentId,
        idUser: this.userProfile!.idUser,
        type: 'LIKE'
      };
      const resp = await firstValueFrom(this.postsService.createCommentLike(data));


      if (resp.body && resp.body.id) {
        this.createdCommentLike(resp.body)
      }
      // this._alert.next('Comentário curtido com sucesso.');
      // this.alertType = 'success';
      this.refreshInteractions.emit()

      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
      this.toastrService.show('Ocorreu um erro ao tentar curtir o comentário. Tente novamente mais tarde.', 'error');
    }
  }

  async unlikeCommentPost(likeId: number, type: 'comment' | 'post' = 'comment') {
    this.loading = true;
    try {
      const resp = await firstValueFrom(this.postsService.deleteLike(likeId.toString()));


      if (type === 'comment') {
        this.removedCommentLike(likeId)
        // this._alert.next('Curtida removida do comentário com sucesso.');
        // this.alertType = 'success';
      } else {
        this.removedPostLike(likeId)
        // this._alert.next('Curtida removida do post com sucesso.');
        // this.alertType = 'success';
      }
      this.refreshInteractions.emit()

      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;

      if (type === 'comment') {
        this.toastrService.show('Ocorreu um erro ao tentar remover a curtida do comentário. Tente novamente mais tarde.', 'error');
      } else {
        this.toastrService.show('Ocorreu um erro ao tentar remover a curtida do post. Tente novamente mais tarde.', 'error');
      }
    }
  }



  openModalCommunityMembers(postId: number) {
    const dialogRef = this.dialog.open(ModalCommunityMembersComponent, {
      data: {
        participants: this.participants.filter(
          participant => participant.idUser !== this.userProfile!.idUser
        ),
        userProfile: this.userProfile,
        userProfilePlan: this.userProfilePlan,
        userConnections: this.userConnections,
        type: 'share',
        communityItem: this.communityItem,
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
        if (result && result.success && result.idUser) {
          await this.sharePost(postId, result.idUser)
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

  createdPostLike(like: Like) {
    this.post.like = like;
    this.post.likes += 1;
  }

  removedPostLike(likeID: number) {
    this.post.like = undefined;
    this.post.likes = Math.max(this.post.likes - 1, 0);
  }
  createdCommentLike(like: Like) {
    this.commentsPost.forEach(comment => {
      if (comment.id === like.comment?.id) {
        comment.like = like;
        comment.likes += 1;
      }
      if (comment.answers) {
        comment.answers.forEach(answer => {
          if (answer.id === like.comment?.id) {
            answer.like = like;
            answer.likes += 1;
          }
        });
      }
    });
  }
  removedCommentLike(likeID: number) {
    this.commentsPost.forEach(comment => {
      if (comment.like?.id === likeID) {
        comment.like = undefined;
        comment.likes = Math.max(comment.likes - 1, 0);
      }
      if (comment.answers) {
        comment.answers.forEach(answer => {
          if (answer.like?.id === likeID) {
            answer.like = undefined;
            answer.likes = Math.max(answer.likes - 1, 0);
          }
        });
      }
    });
  }

  async openParticipantProfile(event: Event, id: string) {
    event.stopPropagation();
    window.open(`https://sluper.bio/${id}`);
  }
}