<section id="side-bar-options">

    <div class="side-bar-item" *ngIf="modalType === 'category'" (click)="closeModal('openEditCategory')">
        <i class="bi bi-pencil-square"></i>
        <span>Editar Categoria</span>
    </div>
    <div class="side-bar-item" *ngIf="modalType === 'category'" (click)="closeModal('openDeleteCategory')">
        <i class="bi bi-trash"></i>
        <span>Deletar Categoria</span>
    </div>

    <div class="side-bar-item" *ngIf="modalType === 'channel'" (click)="closeModal('openEditChannel')">
        <i class="bi bi-pencil-square"></i>
        <span>Editar Canal</span>
    </div>
    <div class="side-bar-item" *ngIf="modalType === 'channel'" (click)="closeModal('openMoveChannel')">
        <i class="bi bi-arrow-clockwise"></i>
        <span>Mover para Categoria</span>
    </div>
    <div class="side-bar-item" *ngIf="modalType === 'channel'" (click)="closeModal('openDeleteChannel')">
        <i class="bi bi-trash"></i>
        <span>Deletar Canal</span>
    </div>

</section>